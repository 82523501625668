@import '~sema-ui-components/dist/components/styles/variables';

.loginLayout {
  max-width: 1024px;
  display: flex;

  .login {
    display: inline-block;
    width: 400px;

    background: #fff;
    border: 1px solid $gray-mid;
    font-size: 16px;
    min-height: 300px;
    color: gray(dark);
    letter-spacing: 0;
    box-sizing: border-box;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 28px;
    padding-bottom: 20px;

    .strongLogin {
    }

    .weakLogin {
      margin-top: 40px;

      .loginButtons {
        display: flex;
        align-items: center;

        .loginButton {
          display: inline;
        }

        .forgottenPasswordButton {
          margin-left: 16px;
        }
      }
    }

    .newUser {
    }
  }

  .description {
    display: inline-block;
    width: 45%;

    .descriptionContent {
      width: 80%;
      margin: auto;

      .noBottomMargin {
        margin-bottom: 0;
      }
    }
  }
}
