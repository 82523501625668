@import '../../../../node_modules/sema-ui-components/dist/components/styles/variables';

.base {
  background: #fff;
  border: 1px solid $gray-mid;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.06);
  color: $gray-dark;
  letter-spacing: 0;
  box-sizing: border-box;
}

.headerDesc {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  margin-left: 5px;
  vertical-align: top;
}

.infoText {
  font-size: 16px;
}

.mandatoryStar {
  color: status-color(danger);
  margin-left: 2px;
}

.infoBox {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

.fieldHeader {
  margin-bottom: 20px;
  display: inline-block;
}

.textField {
  margin-bottom: 10px;

  label {
    font-weight: 400;
    width: 75px;
  }
}

.bottomRuler {
  margin-top: 40px;
}

.areaCounter {
  float: none;
  width: 455px;
}

.saveButton {
  margin-right: 25px;
}

.buttonBar {
  margin-bottom: 40px;
}



