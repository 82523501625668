@import '~sema-ui-components/dist/components/styles/variables';

.topdivider {
  margin-top: 16px;
}

.noresults {
  font-weight: lighter;
  font-style: italic;
  font-family: "Source Sans Pro";
  font-size: 16px;
  color: gray(alt);
}

.statSvg {
  svg {
    width: 245px;
    padding-left: 0px;
    padding-top: 0px;
  }
}

.supportSvg {
  svg {
    width: 210px;
    height: 220px;
    padding-top: 60px;
    padding-left: 50px;
  }
}

.servicesDiv {

  margin-top: 20px;

  .serviceCell {

    margin-bottom: 8px;

    .esuomiLink {
      margin-top: 25px;
      svg {
        margin-left: 12px;
        width: 14px;
        height: 14px;
        polyline {
          stroke: #2A6EBB;
        }
        path {
          stroke: #2A6EBB;
        }
      }
    }

    p {
      height: 60px;
    }

  }

  @media(min-width: 1024px) {
    .serviceCell {
      margin-bottom: 20px;
    }
  }

}

.iconCards {
}

@media(max-width: 1280px) {
  .iconCards {
    flex-direction: column;
  }
}

.lockedTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    margin-bottom: initial;
  }
}

.buttonDiv {
  margin-top: 20px;
}

.news_box {
  background: white;
  margin: 0 -9999rem;
  padding: 70px 9999rem;
}

.bottom_section {
  margin-top: 70px;
}

.upper_section {
  margin-bottom: 50px;
}
