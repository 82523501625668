@import '~sema-ui-components/dist/components/styles/variables';

$inputFieldWidths: (120, 130, 140, 160, 200, 240, 280, 320, 380);

.controlsContainer {
  position: relative;
  display: inline-block;
}

.input {
  padding: 5px;
}

.error {
  border-style: solid;
  border-width: 2px;
  border-color: status-color(danger);
  color: status-color(danger);
}

.errorMessage {
  color: status-color(danger);
  font-size: 14px;
  padding-top: 8px;
}

.calendarButton {
  position: absolute;
  right: 5px;
  bottom: -2px;
  pointer-events: none;
}

.calendarIcon {
  width: 30px;
  height: 30px;
  svg {
    width: 30px;
    height: 30px;
  }
}

.datePicker {
  border-radius: 0;
}

.datePicker :global {
  .react-datepicker__header {
    background: #fff;
    border-bottom: 0;
  }

  .react-datepicker__day:hover {
    border-radius: 14px;
  }

  .react-datepicker__day-name {
    font-weight: 600;
  }

  .react-datepicker__day--selected {
    border-radius: 14px;
  }

  .react-datepicker__navigation--previous {
    border-right-color: #888;
  }

  .react-datepicker__navigation--next {
    border-left-color: #888;
  }
}

@each $w in $inputFieldWidths {
  &.w#{$w} {
    width: #{$w}px;
    input {
      width: #{$w}px;
    }
  }
}
