@import '~sema-ui-components/dist/components/styles/variables';

.base {
  background: #fff;
  border: 1px solid $gray-mid;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.06);
  color: $gray-dark;
  letter-spacing: 0;
  box-sizing: border-box;
}
