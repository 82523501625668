@import '~sema-ui-components/dist/components/styles/variables';

.page {
  width: 100%;
  background: #fff;
  font-size: 18px;
  color: $gray-dark;
  letter-spacing: 0;
  box-sizing: border-box;
  padding: 70px 0 50px 0;

  h3 {
    margin-bottom: 15px;
  }

  .linkList {
    display: flex;
    flex-direction: column;
    span {
      line-height: 28px;
    }
  }

    .bulletcell {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      vertical-align: top;

      .numberCircle {
        flex-shrink: 0;
        border-radius: 50%;

        width: 40px;
        height: 40px;
        position: relative;

        border: 2px solid icon-color(accent);
        color: icon-color(accent);
        text-align: center;

        font-size: 15px;
        font-weight: bold;
        display: inline-block;
        vertical-align: middle;
        line-height: 36px;

        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
      }

      .bulletlistcontent {
        margin-left: 20px;
        width: 100%;
      }
    }

  .infoBox {
    margin-top: 20px;
    p {
      margin-left: 10px;
      margin-bottom: 0;
    }
  }

  .specialLine {
    margin-top: 6px;
    margin-bottom: 30px;
  }

  .code {
    display: inline-block;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 16px;
    background-color: bg-color(darker);
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 5%;
    margin-left: 12px;
    vertical-align: middle;
  }
}

.subSection {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 10px;
  .content {
    margin-left: 20px;
  }

  p {
    line-height: 20px;
    font-size: 18px;
    margin-bottom: 10px;
    text-align: left;
  }

  .lineBreaker {
    span {
      display: block;
    }
  }
}

.formBase {

  h4 {
    margin-bottom: 18px;
  }

  label {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 16px;
    font-weight: 600;
  }

  .thinLabel {
    font-weight: normal;
  }

  .subDescriptionLabel {
    margin-bottom: 12px;
  }

  .value {
    font-size: 18px;
    line-height: 20px;
  }

  .description {
    font-size: 18px;
    line-height: 27px;
    max-width: 780px;
  }

  .subDescription {
    max-width: 680px;
    font-size: 14px;
    line-height: 18px;
    color: text-color(secondary);
    margin-bottom: 10px;
  }

  .checkboxDescription {
    margin-left: 45px;
    display: inline-block;
  }

  .narrowFormPart {
    max-width: 680px;
  }

  .sectionSeparator {
    margin-top: 50px;
  }

  .sectionSeparatorLine {
    padding-bottom: 16px;
    -webkit-box-shadow: 0 1px 0 0 #CCC;
    -moz-box-shadow: 0 1px 0 0 #CCC;
    box-shadow: 0 1px 0 0 #CCC;
    margin-bottom: 20px;
  }

  .extraPadding {
    padding-bottom: 40px;
  }

  .radioButtonVertical {
    margin-bottom: 15px;
    font-weight: normal;
  }

  .infoBox {
    display: inline-block;
    vertical-align: middle;
    margin-left: 7px;

    .headerTitle {
      font-size: 13px;
      line-height: 20px;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 10px;
      display: block;
    }

    p {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 20px;
    }

    p:last-child {
      margin-bottom: 0;
    }

    ul {
      margin-bottom: 0;
      li {
        font-size: 14px;
        line-height: 18px;
        font-weight: normal;
      }
    }

    h5 {
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 10px;
    }
  }

  .formGroup {
    margin-bottom: 24px;

    &.last {
      margin-bottom: 0
    }
  }

  .staticFormGroup {
    label {
      margin-bottom: 10px;
    }
    p {
      font-size: 18px;
      line-height: 20px;
    }

    .subDescription {
      font-size: 14px;
      line-height: 18px;
      color: text-color(secondary);
      margin-bottom: 10px;
    }

    margin-bottom: 20px;

    &.last {
      margin-bottom: 0
    }
  }

  .formInlineGroup {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row;
    flex-flow: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    .formGroup {
      margin-right: 20px;
    }
    .staticFormGroup {
      margin-right: 20px;
    }

  }

  .question {
    margin-top: 44px;
    max-width: 680px;
    .labelSection {
      display: flex;
      flex-direction: row;
      .infoBox {
        display: inline-block;
        position: absolute;
        margin-top: -5px;
        margin-left: 0;
      }
    }
    .extraContent {
      border-left: 1px solid icon-color(primary);
      padding-left: 30px;
      margin-top: 20px;
      max-width: 611px;
      .question {
        margin-top: 24px;
      }
    }
  }

  .contactInfo {
    margin-top: 24px;
  }

  .fileUploadPanel {
    max-width: 380px;
  }

  .readMoreButton {
    font-weight: normal;
    font-size: 18px;
    display: block;
    margin-top: 10px;
  }

  .addMoreButton {
    font-weight: 300;
  }

  .submitButton {
    margin-top: 20px;
  }

  .serviceDeleteButton {
    font-weight: normal;
    margin-left: 10px;
  }

  .dateField {
    display: block;
    input {
      padding-left: 10px;
    }
  }

  .descriptionList {
    max-width: 700px;

    ul {
      padding-left: 25px;
      li {
        margin-top: 20px;
        line-height: 20px;
      }
    }
  }

  .innerContent {
    border-left: 1px solid border-color(input);
    max-width: 380px;
    padding-left: 30px;
    margin-top: 15px;
  }

  .rowElements {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .rowElement {
      margin-right: 20px;
    }
  }

  .checkBox {
    margin-top: 24px;
    span {
      font-weight: normal;
    }
  }
}

.anchorLink {
  cursor: pointer;
  color: interaction-color(primary);
  &:hover {
    text-decoration: underline;
  }
}
