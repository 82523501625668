@import '~sema-ui-components/dist/components/styles/variables';

$borderColor: bg-border-color(border);

.base {
  border: 1px solid $borderColor;
  padding-right: 0;
}

.aside {
  border-right: 1px solid $borderColor;
  background: bg-border-color(light);
  font-size: 16px;
  color: #282828;
}

.rightside {
  background-color: white;
  padding-right: 0;
  padding-left: 0;
}
