@import '~suomifi-design-tokens/dist/tokens';

.container {
  display: flex;
  flex-direction: column;
  gap: $fi-spacing-m;
  margin-bottom: $fi-spacing-m;
}

.group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.expanderContentHeader {
  margin-bottom: $fi-spacing-m;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
