@import '~suomifi-design-tokens/dist/tokens';

.bgBlock {
    background-color: #F7FAFD;
    border: 1px solid #C8CDD0;
    padding: $fi-spacing-l $fi-spacing-l $fi-spacing-s;
    margin-bottom: $fi-spacing-l;
}

.listAnswer {
  margin-top: 2rem;
}

.listSubHeader {
  font-weight: bold;
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: .5rem;

}
