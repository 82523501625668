@import '~sema-ui-components/dist/components/styles/variables';

.dropzone {
  padding: 20px;
  border: 2px dashed gray(mid);
  box-sizing: border-box;
  line-height: 20px;
  font-size: 15px;
  cursor: pointer;
  text-align: center;

  &.error {
    border: 2px solid brand(warning);
  }

  &.dropzoneDisabled {
    background-color: gray(light);
    border: 2px dashed gray(mid);
  }
}

.dropzoneInfo {
  margin: 10px 0 10px 0;
  text-align: center;
  color: gray(mid);
}

.dropzoneFileContainer {
}

.dropzoneFile {
  padding: 10px;
  margin-bottom: 10px;
  font-weight: 700;
  display: inline-block;
  width: 100%;
  background-color: gray(light);
  &:last-child {
    margin-bottom: 0;
  }
}

.dropzoneActice {
  border: 2px brand(blue) solid;
}

.removeFile {
  float: right;
  width: 13px;
}

.uploadTitle {
  margin-bottom: 6px;
}

.addButton {
  margin-top: 20px;
}

.errorMessage {
  line-height: 20px;
  font-size: 13px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: brand(warning);
  text-align: left;
  display: inline-block;
  font-weight: 600;
}
