@import '~suomifi-design-tokens/dist/tokens';

.container {
  background: $fi-color-white-base;
  border: 1px solid $fi-color-depth-light1;
  margin: 0;
  padding: $fi-spacing-l;
}

.header {
  display: flex;
  align-items: center;
  padding-bottom: $fi-spacing-s;
  margin-bottom: $fi-spacing-l;
  border-bottom: 1px solid $fi-color-depth-light1;
}

.icon {
  font-size: 50px;
  line-height: 0;
  margin-right: $fi-spacing-s;
}
