@import '~suomifi-design-tokens/dist/tokens';

.spacer {
    margin: 1rem 0;
  }

.ip4 {

    &__container {
        border-bottom: 1px solid bg-border-color(border);
        background: #F7FAFD;
        padding: 1rem;
        margin-bottom: 1.5rem;
        margin-top: .5rem;
        border: 1px solid #C8CDD0;
    }

    &__flexcontainer {
        display: flex;
        flex-direction: row;
    }

    &__button {
        display: flex;
        align-items: flex-end;
        justify-content: end;
        width: 20rem;
        padding-left: 3rem;
    }
}