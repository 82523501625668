@import '~sema-ui-components/dist/components/styles/variables';
@import '~sema-ui-components/dist/components/styles/colors';

.base {
  margin-top: 70px;

  &::before {
    height: 1px;
    display: block;
    content: '';
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #e1e1e1 10%, #e1e1e1 90%, rgba(255, 255, 255, 0) 100%);

    @media #{bp('>1440px')} {
      background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #e1e1e1 20%, #e1e1e1 80%, rgba(255, 255, 255, 0) 100%);
    }
  }

  .wrap {
    background-color: #fff;
    min-height: 250px;
    padding-bottom: 30px;
  }

  .head {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 20px;
    letter-spacing: -0.31px;
  }

  .leftListCol {
    flex: 0 0 300px;
    text-decoration: underline
  }

  .rightListCol {
    flex: 0 0 280px;
    text-decoration: underline
  }


  .logo {
    margin-top: 42px;
    text-align: right;
  }

  .description {
    margin-top: 13px;
    font-size: 18px;
    line-height: 25px;
    font-weight: 400;
    text-align: right;
    color: text-color(secondary);
  }

  .toolBar {
    margin-top: 31px;
    justify-content: flex-end;
    display: flex;
  }

  .linkList {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      line-height: 30px;
      a {
        color: $gray-dark;
      }
    }
  }

  @media (max-width: 767px) {
    .leftListCol {
      flex: inherit;
    }

    .rightListCol {
      flex: inherit;
    }

    .linkList {
      li {
        text-align: center;
      }
    }

    .head, .logo, .description {
      text-align: center;
    }

    .toolBar {
      justify-content: space-around;
    }
  }

  .langSwitcher {
    flex: 0 0 257px;
    border-right: 1px solid $gray-mid;
  }

  .someIcons {
    text-align: right;

    img, span {
      margin-left: 25px;
      height: 24px;
    }

  }

  .hr {
    width: 100%;
    height: 1px;
    background-color: $gray-dark;
    margin-bottom: 20px;
  }
}

.simpleFooter {
  .head {
    line-height: 26px;
    margin-bottom: 5px;
    margin-top: 45px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }
}

.onMobile {
  display: none;
}
.aboveMobile {
  display: block;
}

@media #{$tablet} {
  .onMobile {
    display: block;
  }
  .aboveMobile {
    display: none;
  }
}

.scrollBtnContainer {
  $side: 50px;

  position: relative;
  top: -25px;
  margin: 0 auto;
  cursor: pointer;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border: 1px solid gray(mid);
    height: $side;
    width: $side;
    border-radius: $side / 2;
    color: gray(light-base);

    svg {
      height: 30px;
    }
  }
}