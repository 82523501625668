@import '~sema-ui-components/dist/components/styles/variables';

.briefDiv {
  margin-bottom: 40px;

  h3 {
    margin-bottom: 20px;
  }
}

.briefCategory {
  color: text-color(secondary);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 15px;
}

.briefContent {
  margin-bottom: 20px;
}

.briefLink {
  font-size: 18px;
  margin-bottom: 40px;
}

.categoryListDiv {
  h4 {
    margin-bottom: 20px;
  }

  margin-bottom: 40px;
}

.tags {
  display: inline-block;
  margin-bottom: 10px;
}

.tag {
  font-size: 14px;
  border-radius: 2px;
  margin-right: 6px;
  margin-bottom: 10px;
  display: inline-block;
  line-height: 20px;
  padding-left: 6px;
  padding-right: 8px;
  background-color: bg-color(dark);
}

.tagText {
  font-size: 14px;
  padding-top: 3px;
  padding-bottom: 5px;
  display: inline-block;
}

.tagRemove {
  width: 20px;
  height: 20px;
  vertical-align: bottom;
  margin-bottom: 8px;
  display: inline-block;

  svg {
    height: 28px;
    fill: icon-color(secondary);
  }
}

.briefCategoryInter {
  display: inline;

  span {
    padding-left: 4px;
    padding-right: 4px;
  }
}

.briefCategoryInter:last-child {
  span {
    display: none;
  }
}
