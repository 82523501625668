@import '~sema-ui-components/dist/components/styles/variables';

.orgList {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    padding-left: 15px;
    overflow: hidden;
    line-height: 20px;
    border: 1px solid gray(mid);
  }

  /*li:not(:last-child) {
    margin-bottom: 10px;
  }*/
}

.activeOrgListItem {
  background-color: #eef5ff;
}

.activeOrg {
  font-weight: bold;
}

.orgLink {
  cursor: pointer;
  color: text-color(primary);
  line-height: 50px;
  display: block;

  img {
    height: 24px;
    width: 24px;
    margin-right: 10px;
  }

  span {
    margin-left: 10px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
    width: 90%;
    max-width: 600px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  h4 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.orgModal {
  padding: 20px 30px 20px 30px;

  > div {
    overflow: auto;
  }
}

.orgUnit {
  margin-left: 35px;
}

.item_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 10px;
}

.dflex {
  display: flex;
}

.svg {
  align-self: center;
  width: 75px;
  height: 42px;
  margin-right: 20px;
}

.org_name_div {
  max-width: 800px;
  margin-right: 30px;
}
