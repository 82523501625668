@import '~sema-ui-components/dist/components/styles/variables';

.base {
  p {
    font-size: 16px;
  }
}

.homeCard {
  margin-bottom: 1.25rem;

  @media #{bp('>mobile', '<=tablet')} {
    margin-bottom: .625rem;
  }

  text-align: center;

  h2 {
    margin: 0 0 10px 0;
    line-height: 20px;
  }

  p {
    margin: 0;
  }

  svg {
    height: 64px;
    max-width: 64px;
  }
}

.centerTitle {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 52px;
}

.bottomAlign {
 margin-bottom: 9px;
}

.article {
  margin-bottom: 20px;
  background: #FFFFFF;
}

.bottomIconBox {
  border-width: 1px;
  border-radius: 2px;
  box-shadow: none;
  padding: 20px;
}

.boxes {
  
  > div {
    margin-bottom: 20px;
  }

  .esuomiLink {
    margin-top: 36px;
    svg {
      margin-left: 12px;
      width: 14px;
      height: 14px;
      polyline {
        stroke: #2A6EBB;
      }
      path {
        stroke: #2A6EBB;
      }
    }
  }
}

.news_box {
  margin-top: 40px;
}
