@import '~sema-ui-components/dist/components/styles/variables';

.thanksBackground {
  position: relative;
  display: table;
  width: 100%;
  height: 390px;
}

.formRow {
  margin-bottom: 20px;
  margin-left: 50px;
  font-size: 18px;
  line-height: 40px;

  textarea {
    resize: none;
  }
}

.contactRow {
  width: 520px;
}

.contactRowError {
  margin-bottom: 0px;
}

.nameDiv {
  display: inline-block;
  margin-right: 20px;
  width: 250px;
  vertical-align: top;
}

.emailDiv {
  display: inline-block;
  width: 250px;
  vertical-align: top;
}

.formRow > div {
  margin-bottom: 0px;
}

.formArea {
  margin-left: 20px;
}

.sendButton {
  margin-top: 10px;
  margin-left: 50px;
  padding: 8px 30px;
}

.footnote {
  font-size: 13px;
  font-weight: 200;
  width: 40%;
  line-height: 16px;
  margin-top: 10px;
}

.emailField {
  display: none;
}

.errorText {
  color: brand(warning);
}

.mandatoryText {
  font-size: 13px;
  color: #c13832;
}

.thanksArea {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding-top: 150px;
  -moz-animation: move 0.5s ease-out 0.3s 1 forwards; /* Firefox */
  -webkit-animation: move 0.5s ease-out 0.3s 1 forwards; /* Safari and Chrome */
  -ms-animation: move 0.5s ease-out 0.3s 1 forwards; /* IE10 */
  -o-animation: move 0.5s ease-out 0.3s 1 forwards; /* Opera */
  animation: move 0.5s ease-out 0.3s 1 forwards;
}

@-webkit-keyframes move { /* Chrome, Safari */
  0% {padding-top:150px;}
  100% {padding-top:0px;}
}
@-moz-keyframes move { /* FF */
  0% {padding-top:150px;}
  100% {padding-top:0px;}
}
@-ms-keyframes move { /* IE10 */
  0% {padding-top:150px;}
  100% {padding-top:0px;}
}
@-o-keyframes move { /* Opera */
  0% {padding-top:150px;}
  100% {padding-top:0px;}
}
@keyframes move {
  0% {padding-top:150px;}
  100% {padding-top:0px;}
}

.animatedForm {
  position: absolute;
  top: 0px;
  left: 0px;

  -moz-animation: moveForm 1s ease-out 0s 1 forwards; /* Firefox */
  -webkit-animation: moveForm 1s ease-out 0s 1 forwards; /* Safari and Chrome */
  -ms-animation: moveForm 1s ease-out 0s 1 forwards; /* IE10 */
  -o-animation: moveForm 1s ease-out 0s 1 forwards; /* Opera */
  animation: moveForm 1s ease-out 0s 1 forwards;
}

@-webkit-keyframes moveForm { /* Chrome, Safari */
  0% {padding-left:0px; opacity: 1;}
  100% {padding-left:150px; opacity: 0;}
}
@-moz-keyframes moveForm { /* FF */
  0% {padding-left:0px; opacity: 1;}
  100% {padding-left:150px; opacity: 0;}
}
@-ms-keyframes moveForm { /* IE10 */
  0% {padding-left:0px; opacity: 1;}
  100% {padding-left:150px; opacity: 0;}
}
@-o-keyframes moveForm { /* Opera */
  0% {padding-left:0px; opacity: 1;}
  100% {padding-left:150px; opacity: 0;}
}
@keyframes moveForm {
  0% {padding-left:0px; opacity: 1;}
  100% {padding-left:150px; opacity: 0;}
}

.thanksPic {
  width: 192px;
  height: 192px;
  margin: auto;
}

.thanksText {
  font-size: 38px;
  font-weight: 300;
  position:relative;
  overflow:hidden;
  text-align: center;
  display: inline;
}

.fadingEffect {
  position:absolute;
  top:0; bottom:0; right:0;
  width: 100%;
  background: #fff;
  -moz-animation: show 1s linear 0.8s 1 forwards; /* Firefox */
  -webkit-animation: show 1s linear 0.8s 1 forwards; /* Safari and Chrome */
  -ms-animation: show 1s linear 0.8s 1 forwards; /* IE10 */
  -o-animation: show 1s linear 0.8s 1 forwards; /* Opera */
  animation: show 1s linear 0.8s 1 forwards;
}

@-webkit-keyframes show { /* Chrome, Safari */
  0% {width:100%;}
  100% {width:0%;}
}
@-moz-keyframes show { /* FF */
  0% {width:100%;}
  100% {width:0%;}
}
@-ms-keyframes show { /* IE10 */
  0% {width:100%;}
  100% {width:0%;}
}
@-o-keyframes show { /* Opera */
  0% {width:100%;}
  100% {width:0%;}
}
@keyframes show {
  0% {width:100%}
  100% {width:0%}
}

