@import '~sema-ui-components/dist/components/styles/variables';

.dateField {
  .date {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 5px;
  }
  .time {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 0;
  }
}
