@import '~suomifi-design-tokens/dist/tokens';

.container {
  display: flex;
  flex-direction: column;
  gap: $fi-spacing-m;
  margin-bottom: $fi-spacing-m;
}

.preview {
  display: flex;
  flex-direction: column;
  gap: $fi-spacing-m 0;

  div {
    display: flex;
    flex-direction: column;
  }
}

.fields {
  display: flex;
  flex-direction: column;
  gap: $fi-spacing-m 0;
}