.loaderContainer {
  position: relative;
  top: 50%;
  margin-left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: #fff;
  border-top: 3px solid #023b84;
  min-width: 260px;
  z-index: 667;
}

.loaderContent {
  background-color: white;
  margin: 24px;
  padding-bottom: 12px;
}

.loaderTitle {
  display: flex;
  width: 100%;
  justify-content: center;
  font-weight: bold;
  margin-bottom: 12px;
}

.loaderGraphic {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 12px;
}

.loaderProgress {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 12px;
}

.loaderDescription {
  display: flex;
  width: 100%;
  justify-content: center;
}

.closeLoader {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 12px;
}