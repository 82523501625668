@import '~suomifi-design-tokens/dist/tokens';

.breadCrumb {
    margin-top: $fi-spacing-l;
    margin-bottom: $fi-spacing-m;
}

.container {
    background: $fi-color-white-base;
    border: 1px solid $fi-color-depth-light1;
    padding: $fi-spacing-xl;
}

.flexContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media only screen and (min-width: 1280px) {
        flex-direction: row;
    }
    
}

.flexItemRight {
    width: 100%;
    order: 1;

    @media only screen and (min-width: 1280px) {
        width: 30rem;
        padding-left: 1rem;
        order: 2;
    }
}

.flexItemLeft {
    order: 2;
    width: 100%;
    
    @media only screen and (min-width: 1280px) {
        padding-right: 1rem;
        order: 1;
    }
}

.detailsContainer {
    padding: $fi-spacing-xl $fi-spacing-m;
    margin-top: $fi-spacing-m;
    margin-bottom: $fi-spacing-xl;
    border: 1px solid $fi-color-depth-light1;
}

.time {
    display: inline-block;
    margin-left: .5rem;
    font-size: 90%;
}

.detail {
    margin-bottom: 1rem;
}