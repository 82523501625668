@import '~sema-ui-components/dist/components/styles/variables';
@import '~suomifi-design-tokens/dist/tokens';

.formBase {
  padding-left: 30px;
  padding-right: 30px;
  font-size: 18px;

  h4 {
    margin-bottom: 18px;
  }

  label {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 16px;
    font-weight: 600;

  }

  label[disabled], p[disabled], div[disabled], h4[disabled] {
    color: $fi-color-depth-base;
  }

  .thinLabel {
    font-weight: normal;
  }

  .subDescriptionLabel {
    margin-bottom: 12px;
  }

  .value {
    font-size: 18px;
    line-height: 20px;
  }

  .description {
    font-size: 18px;
    line-height: 27px;
    max-width: 780px;
  }

  .subDescription {
    max-width: 680px;
    font-size: 14px;
    line-height: 18px;
    color: text-color(secondary);
    margin-bottom: 10px;
  }

  .sectionDescription {
    margin-bottom: 24px;
  }

  .checkboxDescription {
    margin-left: 45px;
    display: inline-block;
  }

  .narrowFormPart {
    max-width: 680px;
  }

  .sectionSeparator {
    margin-top: 50px;
  }

  .sectionSeparatorLine {
    padding-bottom: 60px;
    -webkit-box-shadow: 0 1px 0 0 #ccc;
    -moz-box-shadow: 0 1px 0 0 #ccc;
    box-shadow: 0 1px 0 0 #ccc;
    margin-bottom: 70px;
  }

  .sectionSeparatorLine2 {
    padding-bottom: 26px;
    -webkit-box-shadow: 0 1px 0 0 #ccc;
    -moz-box-shadow: 0 1px 0 0 #ccc;
    box-shadow: 0 1px 0 0 #ccc;
    margin-bottom: 40px;
  }

  .radioButtonVertical {
    margin-bottom: 15px;
    font-weight: normal;
  }

  .infoBox {
    display: inline-block;
    vertical-align: middle;
    margin-left: 7px;

    .headerTitle {
      font-size: 13px;
      line-height: 20px;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 10px;
      display: block;
    }

    p {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 20px;
    }

    p:last-child {
      margin-bottom: 0;
    }

    ul {
      margin-bottom: 0;
      li {
        font-size: 14px;
        line-height: 18px;
        font-weight: normal;
      }
    }

    h5 {
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 10px;
    }
  }

  .formGroup {
    margin-bottom: 24px;

    &.last {
      margin-bottom: 0;
    }

    .checkboxItem {
      display: flex;

      .checkboxLabel {
        margin-left: 8px;
      }
    }
  }

  .staticFormGroup {
    label {
      margin-bottom: 10px;
    }
    p {
      font-size: 18px;
      line-height: 20px;
    }

    .subDescription {
      font-size: 14px;
      line-height: 18px;
      color: text-color(secondary);
      margin-bottom: 10px;
    }

    margin-bottom: 20px;

    &.last {
      margin-bottom: 0;
    }
  }

  .headingButton {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
  }

  .formInlineGroup {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row;
    flex-flow: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    .formGroup {
      margin-right: 20px;
    }
    .staticFormGroup {
      margin-right: 20px;
    }
  }

  .question {
    margin-top: 24px;
    max-width: 960px;
    .labelSection {
      display: flex;
      flex-direction: row;
      .infoBox {
        display: inline-block;
        position: absolute;
        margin-top: -5px;
        margin-left: 0;
      }
    }
    .extraContent {
      border-left: 1px solid icon-color(primary);
      padding-left: 30px;
      margin-top: 20px;
      max-width: 611px;
      .question {
        margin-top: 24px;
      }
    }
  }

  .contactInfo {
    margin-top: 24px;
  }

  .fileUploadPanel {
    max-width: 380px;
  }

  .readMoreButton {
    font-weight: normal;
    font-size: 18px;
    display: block;
    margin-top: 10px;
  }

  .addMoreButton {
    font-weight: 300;
    margin-top: 21px;
  }

  .submitButton {
    margin-top: 40px;
    margin-bottom: 70px;
  }

  .serviceDeleteButton {
    font-weight: normal;
    margin-left: 10px;
  }

  .attachments {
    display: flex;
    flex-flow: wrap;

    .attachment {
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 5px;
      margin-bottom: 5px;
      flex-grow: 1;
      width: 200px;
    }
  }

  .serviceName {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    .serviceNameField {
      margin-right: 6px;
    }
  }

  .notificationContact {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    .contactLabel {
      width: 150px;
      text-align: left;
      margin-right: 4px;
    }
  }

  .lawSections {
    display: flex;
    > label {
      margin-right: 42px;
    }
  }

  .lawSectionBlock {
    display: flex;
  }

  .subSection {
    margin-left: 30px;
  }

  .numberedList {
    margin-bottom: 10px;
    ol {
      counter-reset: item;
    }
    ol > li {
      counter-increment: item;
    }

    ol ol > li {
      display: block;
    }

    ol ol > li:before {
      content: counters(item, '.') '. ';
      margin-left: -20px;
    }
  }

  .listHeader {
    font-weight: 600;
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 12px;
  }

  .listSubHeader {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
  }

  .nameLabel {
    width: 100px;
  }

  .errorText {
    color: status-color(danger);
    font-size: 14px;
  }

  .link {
    margin-left: 4px;
  }

  a[disabled] {
    pointer-events: none;
    color: $fi-color-depth-base;
  }

  .ipField {
    display: inline-block;
  }

  .ipList {
    list-style-type: none;
    padding-left: 0;
  }

  
}
