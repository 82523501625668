@import '~suomifi-design-tokens/dist/tokens';

.enrollment {
  background: $fi-color-white-base;
  border: 1px solid $fi-color-depth-light1;
  margin: 0;
  padding: $fi-spacing-xl;
  max-width: 40rem;

  &__radioButtonGroup {
    margin-bottom: $fi-spacing-xxxxl;
  }

  &__heading {
    margin: $fi-spacing-m 0 !important;
  }

  &__button {
    margin-left: $fi-spacing-s;
  }

  &__buttonContainer {
    display: flex; 
    justify-content: flex-end;
  }
}

