@import '~suomifi-design-tokens/dist/tokens';

.container {
  display: flex;
  flex-direction: column;
  gap: $fi-spacing-m;
  margin-bottom: $fi-spacing-m;
}

.group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.expanderContentHeader {
  margin-bottom: $fi-spacing-m;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.expander {
  & > div {
    overflow: visible !important;
  }
}

.hr {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.field {
  margin-top: 1rem;
}

.textarea {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.textfield {
  margin-top: 1.25rem;
}