@import '~suomifi-design-tokens/dist/tokens';

.container {
  margin: 0;
  padding: $fi-spacing-xl;
}

.header {
  margin-bottom: $fi-spacing-l;
}

.actions {
  margin-top: $fi-spacing-l;
}

/* override global style with important */
.button {
  margin: 0 $fi-spacing-s 0 0 !important;
}
