@import '~suomifi-design-tokens/dist/tokens';

.container {
  background: $fi-color-depth-light2;
  border: 1px solid $fi-color-depth-light1;
  margin: 0 0 -1px 0;
  padding: 0;
}

.footer {
  background: $fi-color-depth-light3;
  border-top: 1px solid $fi-color-depth-light1;
  padding: $fi-spacing-xs $fi-spacing-s;
}
