@import '~sema-ui-components/dist/components/styles/variables';
.introduction {
  font-size: 1.3em;
  margin-top: 5vh;
}

.blockHighlightBase {
  background-color: #2a6ebb;
  width: 40px;
  height:4px;
  margin: 20px 0 20px 0;
}
