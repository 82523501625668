@import '~sema-ui-components/dist/components/styles/variables';

.listbase {
  margin-top: 60px;

  h2 {
    margin-bottom: 60px;
  }
}

.showMoreButton {
  margin-bottom: 50px;
}


