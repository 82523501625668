@import '~suomifi-design-tokens/dist/tokens';

.container {
  margin: 0;
  padding: 0;
  display: inline-block;
}

.listContainer {
  margin: 0;
  padding: 0;
  display: inline-flex;
  align-items: center;
}

.listItem {
  display: inline-block;
  padding: 0;
}

.link {
  @include fi-text-body-text;
  display: flex;
  width: 38px;
  height: 38px;
  justify-content: center;
  align-items: center;
  background: $fi-color-white-base;
  margin: -1px;
  border: 1px solid $fi-color-depth-light1;
  color: $fi-color-highlight-base;
  text-decoration: none;
  overflow: hidden;

  &:active,
  &:focus,
  &:hover,
  &:visited {
    color: $fi-color-highlight-base;
    text-decoration: none;
  }
}

.icon {
  fill: $fi-color-highlight-base;
}

.linkActive {
  background: $fi-color-highlight-base;
  color: $fi-color-white-base;

  &:active,
  &:focus,
  &:hover,
  &:visited {
    color: $fi-color-white-base;
  }
}

.disabled .link {
  color: $fi-color-depth-base;
  pointer-events: none;
}

.disabled .icon {
  fill: $fi-color-depth-base;
}
