@import '~sema-ui-components/dist/components/styles/variables';

.base {
  color: #fff;
  background: transparent;
  display: flex;
  width: 100%;
}

.userData {
  flex: 1;
  flex-basis: auto;
  box-sizing: border-box;
  line-height: 15px;
  max-width: 100%;

  .username, .userTitle {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
  }

  .username {
    color: text-color(primary);
    font-weight: 600;
    margin-bottom: 4px;
  }

  .userTitle {
    color: text-color(secondary);

    .userOrg {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-bottom: 5px;
    }
  }

  &.inverse {
    .username, .userTitle {
      color: currentColor;
    }
  }

  &.right {
    .username, .userTitle {
      text-align: right;
    }
  }

}
