@import '~sema-ui-components/dist/components/styles/variables';

.page {
  min-height: 700px;
  margin-top: 50px;
}

.signUpButton {
  margin-top: 14px;
}

.confirmRegTitle {
  margin-bottom: 51px;
}

.confirmRegButton {
  margin-top: 20px;
}

.completeRegButton {
  margin-top: 35px;
}

.confirmOrgIdRow {
  display: inline-block;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 20px;
}

.confirmOrgIdText {
  display: inline-block;
  margin-right: 10px;
}

.confirmGetOrgButton {
  vertical-align: top;
}

.confirmOrgWarning {
  background: #fff;
  border: 1px solid $gray-mid;
  display: inline-block;
  margin-top: 40px;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 100%;
  color: gray(dark);
  padding: 10px;

  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.06);
  -webkit-backdrop-filter: blur(3px);
  overflow: hidden;
}

.confirmIconContainer {
  vertical-align: middle;
  display: inline-block;
  margin: 10px 10px 10px 10px;
  width: 40px;
  height: 40px;
}

.confirmOrgContainer {
  display: inline-block;
  margin-left: 60px;
  margin-bottom: 20px;
}

.confirmOrgTitle {
  display: inline;
  font-weight: bold;
}

.confirmInfoIcon {
  fill: brand(blue);
}

.confirmRequestInviteButton {
  margin-top: 40px;
}

.invLogoutButton {
  margin-top: 40px;
}

.invRequestInfoName {
  display: inline-block;
}

.invRequestInfoLabel {
  margin-top: 24px;
  margin-down: 6px;
  display: inline-block;
  font-weight: 600;
  width: 156px;
}

.invRequestFieldLabel {
  width: 156px;
}

.inviteEmailField {
  margin-top: 24px;
  margin-down: 6px;
}

.invSendRequestButton {
  margin-top: 40px;
}

.searchField {
  width: 420px;
}

.activeOrg {
  font-weight: bold;
}

.orgListItem {
  padding-left: 15px;
  overflow: hidden;
  line-height: 20px;
  border: 1px solid gray(mid);
  margin-bottom: 20px;
}

.orgLink {
  cursor: pointer;
  color: text-color(primary);
  line-height: 50px;
  display: block;

  img {
    height: 24px;
    width: 24px;
    margin-right: 10px;
  }

  span {
    display: inline-block;
    vertical-align: middle;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.button {
  margin-top: 34px;
}
.dataTable {
  border-collapse: separate;
  border-spacing: 0 20px;
}

.dataTable span.large {
  font-size: 18px;
}
.dataTable th {
  padding-right: 30px;
}

.dataTable th, .dataTable td {
  vertical-align: top;
}

.dataTable th span {
  padding-top: 10px;
}

//.dataTable {
//  &.spaced {
//    tr:not(:last-child) td,
//    tr:not(:last-child) th {
//      padding-bottom: 30px;
//    }
//
//    tr.text:not(:last-child) + tr.input:not(:last-child) td,
//    tr.text:not(:last-child) + tr.input:not(:last-child) th {
//      padding-bottom: 26px;
//    }
//  }
//}

.withDesc {
  display: inline-block;
  margin-right: 10px;
}

.spaced {
  padding-bottom: 10px;
}

.emailDesc {
  display: inline-block;
}

.emailErrorMessage {
  display: inline-block;
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  color: brand(warning);
}

.titleAfterTable {
  margin-top: 30px;
}

.userInfoTitle {
  margin-top: 34px;
}

.formTitle {
  margin-top: 50px;
}

.regForm label {
  margin-top: 24px;
}

.inlineInput {
  display: inline-block;
  margin-right: 20px;
}

.loadingSpinner {
  margin-top: 24px;
}

.requiredLabel::after {
  color: status-color(danger);
  content: " *";
}

.requiredLabel {
  font-weight: 600;
  white-space: nowrap;
}

.combinedInputs {
  display: flex;

}
.spinner {
  margin-left: 10px;
  height: 14px;
  width: 14px;
}

