@import '~sema-ui-components/dist/components/styles/variables';
@import '~suomifi-design-tokens/dist/tokens';

.base {
  background: #fff;
  border: 1px solid $gray-mid;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.06);
  color: $gray-dark;
  letter-spacing: 0;
  box-sizing: border-box;
}

.disabledSection {
  color: $fi-color-depth-base;
  h2 {
    color: $fi-color-depth-base;
    margin-bottom: 16px;
  }
}

.inputLabel {
  margin-top: 20px;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;
  font-weight: 600;
}

.infoBox {
  display: inline-block;
  vertical-align: middle;
}

.separator {
  background-color: $fi-color-depth-light1;
  height: 2px;
}

.warningMessages {
  background-color: $fi-color-depth-light3;
  border: 1px solid $fi-color-depth-light1;
  font-size: small;
  display: block;
  padding: 20px;
  margin-bottom: 15px;
}

.dropzone {
  padding: 20px;
  border: 2px dashed gray(mid);
  width: 480px;
  height: 120px;
  box-sizing: border-box;
  line-height: 20px;
  font-size: 15px;
  cursor: pointer;

  &.error {
    border: 2px solid brand(warning);
  }

  &.dropzoneDisabled {
    background-color: gray(light);
    border: 2px dashed gray(mid);
  }
}

.dropzoneInfo {
  margin: 30px 0 10px 0;
  text-align: center;
  font-weight: 600;
  color: text-color(secondary);
}

.dropzoneActive {
  border: 2px brand(blue) solid;
}

.xmlField {
  margin-top: 40px;

  textarea {
    height: 550px;
  }

  ::-moz-placeholder {
    color: text-color(primary);
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    line-height: 550px;
  }
  ::-webkit-input-placeholder {
    color: text-color(primary);
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    line-height: 550px;
  }
  :-ms-input-placeholder {
    color: text-color(primary);
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    line-height: 550px;
  }
}

.mandatoryStar {
  color: status-color(danger);
  margin-left: 2px;
}

table {
  table-layout: fixed;
}

th {
  overflow: hidden;
}
