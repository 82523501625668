@import '~sema-ui-components/dist/components/styles/variables';

.tabContent {
  width: 100%;
  background: #fff;
  font-size: 16px;
  color: $gray-dark;
  letter-spacing: 0;
  box-sizing: border-box;
  padding-top: 50px;
  padding-bottom: 50px;

  p {
    font-size: 20px;
    line-height: 30px;
  }

  ul {
    li {
      font-size: 20px;
    }
  }
}

.imageStyle {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
}



.hiddenChild {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.borderedTabContent {
  border: 1px solid $gray-mid;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.06);
}

.author {
  p {
    margin-bottom: 0;
    font-size: 20px;
    line-height: 30px;

  }
  .authorName {
    font-weight: 600;
  }

  margin-bottom: 1rem;
}