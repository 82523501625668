@import '~sema-ui-components/dist/components/styles/variables';

$brand-color: brand(blue);

.base:before,
.base:after,
.base {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load 1.8s infinite ease-in-out;
  animation: load 1.8s infinite ease-in-out;
}

.base {
  font-size: 10px;
  margin: 20px auto 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.base:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.base:after {
  left: 3.5em;
}

.base:before,
.base:after {
  content: '';
  position: absolute;
  top: 0;
}

@-webkit-keyframes load {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em brand(blue);
  }
  40% {
    box-shadow: 0 2.5em 0 0 brand(blue-alt);
  }
}

@keyframes load {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em brand(blue);
  }
  40% {
    box-shadow: 0 2.5em 0 0 brand(blue-alt);
  }
}

.spinnerContainer {
  display: flex;
  flex: 1;
  justify-content: center;
}

.spinner {
  background: secondary-color(lakka);
  padding: 5px 15px 5px 10px;
  color: #fff;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.spinnerIcon {
  border-right-color: #fff;
  border-top-color: #fff;
}

.spinnerText {
  margin-left: 5px;
}

.inlineloader {
  height: .5rem;
  padding-left: .5rem;
  width: 2.25rem;
}

.loaderdot {
  animation-direction: alternate;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-name: fadeOutIn;
  background-color: $brand-color;
  border-radius: 50%;
  display: inline-block;
  height: .5rem;
  margin: 0 .125rem;
  width: .5rem;
}

.loaderdot {
  &:nth-child(1) { animation-delay: 250ms; }
  &:nth-child(2) { animation-delay: 500ms; }
  &:nth-child(3) { animation-delay: 750ms; }
}

@keyframes fadeOutIn {
  0%   { background-color: rgba($brand-color, 1); }
  100% { background-color: rgba($brand-color, 0); }
}


