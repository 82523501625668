@import '~sema-ui-components/dist/components/styles/variables';

.tabContent {
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid $gray-mid;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.06);
  font-size: 16px;
  color: $gray-dark;
  letter-spacing: 0;
  box-sizing: border-box;
}

.infoPanel {
  display: block;
  border: 1px solid icon-color(primary);
  border-radius: 2px;
  background-color: #FFFFFF;
  margin-bottom: 20px;
  h4 {
    margin-bottom: 0px;
  }
}
