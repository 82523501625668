@import '~sema-ui-components/dist/components/styles/variables';

.base {
  list-style: none;
  padding: 0;
  margin: 0;

  div {
    font-size: 18px;
  }
}
