@import '~suomifi-design-tokens/dist/tokens';
@import '~sema-ui-components/dist/components/styles/colors';

.content {
    padding: $fi-spacing-m;
    border: 1px solid bg-border-color(border);
    background-color: bg-border-color(content-bg);
    display: flex;
    flex-direction: column;

    &__heading {
      margin-bottom: $fi-spacing-xs;
    }

    @media only screen and (min-width: 1280px) {
      flex-grow: 1;
    }
  }
