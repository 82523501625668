@import '~suomifi-design-tokens/dist/tokens';
@import '~sema-ui-components/dist/components/styles/colors';

.person {
  &__heading {
    margin-top: $fi-spacing-m;
    margin-bottom: $fi-spacing-l;
  }
}

.spacer {
  margin: 2rem 0;
}

.heading {
  margin-bottom: $fi-spacing-l;
}

.textinput {
  margin-bottom: $fi-spacing-m !important;
}

.paragraph {
  margin-bottom: $fi-spacing-m;
}

.container {
  display: flex;
  flex-direction: column;
  gap: $fi-spacing-m;
  margin-bottom: $fi-spacing-m;
}

.search {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  margin-bottom: $fi-spacing-xs;
}

.removePersonContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}