.base {
  margin-top: 80px;
}

.description {
  word-spacing: 0.1em;
  padding: 0 20px;
  text-align: justify;
  margin-bottom: 37px;
}
