@import '~suomifi-design-tokens/dist/tokens';

.attachmentContainer {
    margin-top: .5rem;
    margin-bottom: 2rem;
    background-color: $fi-color-highlight-light4;
    padding: 2rem;
    border: 1px dashed $fi-color-highlight-dark1;
    min-height: 10rem;
  }

.addButton {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.fileRow {
    margin: 1rem 0;
    background-color: whiteBase;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.success {
    margin-right: .5rem;
    color: $fi-color-success-base;
    margin-top: .25rem;
}

.error {
    margin-right: .5rem;
    color: $fi-color-alert-base;
    margin-top: .25rem;
}

.rowContent {
    display: flex;
    flex-direction: column;
}

.errorMessages {
    margin-top: .25rem;
    font-weight: bold;
    text-transform: uppercase;
    font-size: .75rem;
}