@import '~sema-ui-components/dist/components/styles/variables';

$brand-color: brand-color();
$top-line-height: 4px;

.base {
  background-color: white;
  color: black;
  min-height: 80px;

  @media #{$tablet} {
    height: 4.375rem;
  }
}

.blueLineOnTop {
  border-top: $top-line-height solid;
  border-color: $brand-color;
}

.noLineOnTop {
  margin-top: $top-line-height;
}

.bottomBorder {
  -webkit-box-shadow: 0 1px 0 0 border-color(border);
  -moz-box-shadow: 0 1px 0 0 border-color(border);
  box-shadow: 0 1px 0 0 border-color(border);
}

.navToggler {
  font-size: 30px;
  line-height: 80px;

  &.inverse {
    color: #fff;
  }
}

/* animation transitions */
:global {
  .burger-enter {
    opacity: 0.01;
  }

  .burger-enter.burger-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-in;
  }

  .burger-leave {
    visibility: hidden;
    font-size: 0;
    line-height: 0;
    opacity: 1;
  }

  .burger-leave.burger-leave-active {
    opacity: 0;
  }
}

.headerContentRow {
  display: flex;
  height: 76px;
  justify-content: space-between;
}

.userBox {
  margin-right: 20px;
  width: 150px;
}

.authRow {
  display: flex;
  height: 76px;
  align-items: center;
  justify-content: flex-end;
  padding-left: 20px;
  padding-right: 10px;
}

.searchBoxContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.notificationContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoContainer {
  min-width: 0;
  display: flex;
  align-items: center;
  margin-right: 40px;
}

.registerButton {
  margin-right: 20px;
}

.hamburger {
  margin-top: 10px;

  text-align: center;

  cursor: pointer;
  font-size: 11px;
  text-transform: uppercase;
  svg {
    width: 24px;
    height: 16px;
  }

  &.inverse {
    svg {
      g {
        fill: #fff;
      }
    }

    color: #fff;
  }

  &.active {
    color: icon-color(accent);
    svg {
      g {
        fill: icon-color(accent);
      }
    }
  }

  @media #{$mobile} {
    margin-right: 20px;
  }
}

.skipToContent {
  background-color: $brand-color;
  color: white !important;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  padding: 8px;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.3s;
  z-index: 200;

  &:focus {
    transform: translateY(0%);
  }
}

.dropdownReplacement {
  display: flex;
  flex-direction: column;
}

.logoutTemporaryUserLink {
  text-transform: uppercase;
  color: interaction-color(primary) !important;
  font-size: 12px;
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }
}