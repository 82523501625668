@import '~sema-ui-components/dist/components/styles/typography';
@import '~sema-ui-components/dist/components/styles/tables';
@import '../../utils';

//$extraNegativeMargins: 50.75px;

.base {
  h3 {
    margin-top: 50px;
  }

  p {
    margin-bottom: 30px;
  }

  ul {
    padding: 0 0 0 25px;
    font-size: 18px;
    line-height: 27px;
    li {
      margin-bottom: 10px;
    }
  }

  table {
    @include paha-table;
    margin-bottom: 40px;
  }


  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.textContent > *:first-child{
  margin-top: 0;
}

.debug {
  @include moduleDebug('TextModule');
}
