@import '~sema-ui-components/dist/components/styles/variables';

$hero_phrase_border_color: #fff;

.mainHeader {
  color: #fff;
  background: #05213F url('!!url-loader?limit=10!../../assets/paha-hero.svg') no-repeat center center;
  background-size: cover;

  header {
    background: none;
  }
}

.headerExtra {
  min-height: 410px;

  .customHeader {
    background: none;
  }

  .heroPhrase {
    font-size: 40px;
    line-height: 50px;
    font-weight: 600;
    margin-top: 100px !important;
    text-align: center;
    letter-spacing: -0.5px;

    @media #{$mobile} {
      font-size: 30px;
    }
  }

  .heroDescription {
    text-align: center;

    &:after {
      border-bottom: none !important;
    }
  }
}

.linkStrip {
  margin-top: 0;
  background: rgba(4, 33, 63, 0.80);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  padding-top: 39px;
  padding-bottom: 19px;

  .prettyLink {
    margin-right: 60px;
    text-align: center;
    margin-bottom: 20px;
    display: inline-block;

    @media #{$mobile} {
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    a {
      color: #fff;

      i {
        display: inline-block;
        width: 22px;
        height: 22px;
        vertical-align: middle;
        margin-left: 10px;
      }

      .caretCircle {
        position: relative;
        display: inline-block;
        width: 22px;
        height: 22px;
        vertical-align: top;
        border: 2px solid #EA7125;
        border-radius: 11px;
        margin-left: 10px;

        &:after {
          position: absolute;
          left: 4px;
          top: 5px;
          width: 7px;
          height: 7px;
          content: "";
          border: 0 solid #EA7125;
          border-right-width: 2px;
          border-bottom-width: 2px;
          transform-origin: center center;
          transform: rotate(-45deg);
        }
      }
    }
  }

}

