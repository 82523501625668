@import '~sema-ui-components/dist/components/styles/variables';


.topButtonDiv {
  position: absolute;
  top: 30px;
  right: 0px;
}

.topButton {
  margin-right: 10px;
}

.controlButtons {
  .confirmationButtons {
    float: left;
  }
  .cancelButton {
    float: right;
  }
}

.label {
  margin-left: 0px;
}

.buttonDiv {
  margin-top: 10px;
}

.detailsView {
  padding: 20px;
  padding-right: 0px;
  padding-left: 0px;

  border: 1px solid #CCCCCC;
  display: flex;
  flex-direction: row;

  word-wrap: break-word;
  p {
    font-size: 16px;
    line-height: 24px;
  }
  .contentColumn {
    flex: auto;
  }
}

.leftColumn {
  padding-top: 25px;
  padding-left: 10px;
  h4, h5 {
    margin-bottom: 2px;
    margin-top: 12px;
  }
  word-wrap: break-word;
}

.content {
  border-left: 1px solid #CCCCCC;
  padding-bottom: 25px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 0px;
  background-color: white;
  h3, h4 {
    padding-top: 25px;
  }
  .displayName, .displayOrganizationURL{
    h3 {
      margin-bottom: 1px;
    }
  }
  .subtitle {
    font-style: italic;
    padding-bottom: 25px;
  }
}

