.notifBase {
  top: 10px;
  right: 0px;
  position: fixed;
  z-index: 6666;
}

.notificationDisplay {
  display: block;
  margin-bottom: 30px;
  z-index: 999;
}

.notif-transition-enter {
  opacity    : 0.01;
  transition : opacity .5s ease-in, height 500ms ease-in;
}

.notif-transition-enter-active {
  opacity : 1;
}

.notif-transition-leave {
  opacity    : 1;
  transition : opacity .5s ease-in, height 500ms ease-in;
}

.notif-transition-leave-active {
  opacity : 0.01;
  height: 0;
}
