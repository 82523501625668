@import '~suomifi-design-tokens/dist/tokens';

.roleSelector {
  margin: 0 0 $fi-spacing-xl;
  max-width: 40rem;

  &__radioButtonGroup {
    margin-bottom: $fi-spacing-xxxxl;
  }

  &__heading {
    margin-top: $fi-spacing-s;
    margin-bottom: $fi-spacing-m;
  }

  &__button {
    margin-left: $fi-spacing-s;
  }

  &__buttonContainer {
    display: flex; 
    justify-content: flex-end;
  }
}

