@import '~suomifi-design-tokens/dist/tokens';

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  border-top: 1px solid $fi-color-depth-light1;
}

.footerButton {
  margin: 12px 0px !important;
}

.footerMiddleButton {
  margin-right: 12px !important;
}
