@import '~suomifi-design-tokens/dist/tokens';
@import '~sema-ui-components/dist/components/styles/colors';

.formContainer {
  display: flex;
  flex-direction: column;
  gap: $fi-spacing-xl;
  margin-bottom: $fi-spacing-m;
}

.errorListItemBtn {
  border: none;
  background: transparent;
  text-decoration: underline;
  cursor: pointer;

  &:focus {
    box-shadow: 0 0 0 2px white, 0 0 0 4px $fi-color-accent-secondary;
    outline: none;
  }
}

.previewContainer {
  border: 1px solid bg-border-color(border);
  padding: $fi-spacing-m;
  background-color: $fi-color-highlight-light4;

  dl {
    font-size: 1rem;
    margin: 1rem 0;
  }

  dt {
    font-weight: 600;
  }
}

.textarea {
  margin-top: 2rem !important;
  label {
    margin-bottom: 10px;
  }

  textarea {
    min-height: 8rem;
  }
}