/* latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src:  local('Source Sans Pro Light'),
        local('SourceSansPro-Light'),
        url(./latin_300.woff2) format('woff2'),
        url(./latin_300.woff) format('woff'),
        url(./latin_300.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src:  local('Source Sans Pro Regular'),
        local('SourceSansPro-Regular'),
        url(./latin_400.woff2) format('woff2'),
        url(./latin_400.woff) format('woff'),
        url(./latin_400.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src:  local('Source Sans Pro SemiBold'),
        local('SourceSansPro-SemiBold'),
        url(./latin_600.woff2) format('woff2'),
        url(./latin_600.woff) format('woff'),
        url(./latin_600.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src:  local('Source Sans Pro Bold'),
        local('SourceSansPro-Bold'),
        url(./latin_700.woff2) format('woff2'),
        url(./latin_700.woff) format('woff'),
        url(./latin_700.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
