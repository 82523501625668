@import '~sema-ui-components/dist/components/styles/utils.scss';

.logo {
  height: 32px;

  @media #{$mobile} {
    margin-left: 20px;
  }

  a {
    align-items: center;
    display: flex;
    white-space: nowrap;

    img {
      height: 32px;
      width: 32px;

      @media #{$mobile} {
        height: 24px;
        width: 24px;
      }
    }

    .iconText {
      display: inline-block;
      fill: brand-color();
      height: 21.79px;
      line-height: 22px;
      margin-bottom: -4px;
      margin-left: 10px;
      vertical-align: middle;
      width: 206.45px;

      @media #{$mobile} {
        height: 21.79px * .8;
        width: 206.45px * .8;
      }
    }

    .light {
      fill: #fff;
    }
  }
}
