@import '~sema-ui-components/dist/components/styles/variables';

.busTable {
  table tbody tr {
    p {
      margin-bottom: 0;
    }

    &:nth-child(1) {
      background: #FFF;
      font-weight: 600;
      font-size: 15px;
      line-height: 19px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    &:nth-child(2) {
      background: #FFF;
      padding-top: 5px;
      td {
        border-bottom: 0;
      }
    }
    &:nth-child(3) {
      background: #FFF;
      td {
        border-bottom: 0;
        border-top: 0;
      }
    }
    &:nth-child(4) {
      background: #FFF;
      padding-bottom: 5px;
      td {
        border-top: 0;
      }
    }
    td {
      padding: 25px 15px 25px 15px;
      h4 {
        font-family: 'Source Sans Pro', sans-serif;
        margin-bottom: 5px;
        font-weight: 600;
      }
      p {
        font-size: 15px;
        font-weight: 500;
      }
      &:nth-child(1) {
        padding-left: 20px;
        border-right: 0;
        width: 40%;
      }
      &:nth-child(2) {
        border-left: 0;
      }
    }
  }
}

.base {
  h4 {
    margin-bottom: 5px;
  }

  p {
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 0;
  }
}

.accordion {
  .singleTabHeader {
    font-weight: 600;
  }

  p {
    margin-bottom: 0;
    font-size: 15px;
    line-height: 19px;
    font-weight: 500;
  }

  .icon {
    vertical-align: middle;
    margin-left: 10px;
    height: 17px;
    svg {
      height: 17px;
    }
  }

  &.hideBorder {
    border-width: 0;

    .element {
      border: 1px solid #CCCCCC;
      border-bottom: 1;
      border-left: 0px;
      border-right: 0px;
      border-top: 0px;
    }
  }
}

.shiftToRight {
  margin-left: 28px;
}

.radioLine {
  margin-bottom: 5px;
}

.infoAccordion {

  .header {
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
  }

  h4 {
    font-family: 'Source Sans Pro', sans-serif;
    margin-bottom: 5px;
    font-weight: 600;
  }

  p {
    font-size: 15px;
    font-weight: 500;
  }

  .columnLeft {
    display: inline-block;
    width: 320px;
    margin-right: 30px;
  }

  .columnRight {
    display: inline-block;
  }
}

.generatedField {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

.generateButton {
  vertical-align: middle;
  float: right;
  button {
    padding-left: 15px;
  }
}

.iconInButton {
  vertical-align: middle;
  margin-right: 10px;
  height: 12px;
  svg {
    height: 12px;
  }
}

.newAddressBar {
  padding: 15px 20px 15px 20px;

  .newAddressName {
    margin-right: 30px;
    display: inline-block;
    vertical-align: middle;
  }

  .confirmButtons {
    display: inline-block;
    vertical-align: middle;
  }
}

.addressTable table {
  thead {
    th {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      &:first-child {
        padding-left: 20px;
      }
    }
  }
  tbody {
    tr {

      td:first-child {
        padding-left: 20px;
      }

      td {
        padding: 15px 20px 15px 20px;
      }
    }
  }
}

.belowTab {
  background-color: gray(lighter);
  outline: 5px solid gray(lighter);
  margin-top: 5px;
  padding-top: 35px;
}
.serviceListArea {
  .serviceListTable {
    thead {
      th {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        &:first-child {
          padding-left: 20px;
        }
      }
    }

    tbody {
      tr {
        .linkButton {
          font-size: 16px;
          font-weight: normal;
          line-height: 20px;
        }
        td:first-child, th:first-child {
          width: 230px;
          button {
            cursor: pointer;
          }
        }
      }

      td:first-child {
        padding-left: 20px;
      }
    }
  }
}

.issueSelector {
  float: left;
  margin-right: 30px;
  margin-top: 10px;
}

.issueList {
  display: inline-block;
  width: 100%;
  max-width: 300px;
  margin-top: 10px;
}

.pageHeader {
  margin-top: 40px;

  h1 {
    margin-bottom: 40px;
  }
}

.contentWrapper {
/*  padding: 30px 50px 30px 50px;*/
}