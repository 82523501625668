@import '~sema-ui-components/dist/components/styles/variables';

.base {
  .separator {
    margin-left: 0;
    margin-right: 0;
    border-top: 1px solid border-color(border);
  }

  h2::after {
    display: none;
  }

  h2 {
    margin-bottom: 30px;
  }

  h3 {
    margin-top: 30px;
  }
}

.linkList {
  li {
    margin-bottom: 0px;
  }
}

.contactBox {
  display: inline-block;
  width: 330px;
  margin-right: 20px;
  margin-top: 30px;
  .name {
    margin-bottom: 0;
    letter-spacing: -0.2px;
    line-height: 24px;
    font-weight: 600;
    font-size: 18px;
  }
  .title {
    margin-bottom: 0;
    line-height: 25px;
    font-size: 18px;
  }
  .place {
    margin-bottom: 30px;
    line-height: 25px;
    font-size: 18px;
  }
  .email {
    margin-bottom: 0;
    line-height: 25px;
    font-size: 18px;
  }
  .phone {
    margin-bottom: 0;
    line-height: 25px;
    font-size: 18px;
  }
}

.supportImage {
  max-width: 99%;
  min-width: 99%;
}
