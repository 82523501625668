@import '~sema-ui-components/dist/components/styles/variables';

.base {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;

  li {
    display: inline-block;
    opacity: 0.8;
    background: #000;
    border-radius: 1px;
    font-size: 12px;
    font-weight: 600;

    a {
      color: #FFF;
      text-transform: uppercase;
    }

    padding: 0 5px;
    margin-right: 5px;
    line-height: 26px;
    text-shadow: 0 1px 1px rgba(0,0,0,0.50);

    &:last-child {
      margin-right: 0;
    }
  }
}
