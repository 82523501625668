@import '~sema-ui-components/dist/components/styles/variables';

$extraNegativeMargins: 50.75px;

.tabContent {
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid $gray-mid;
  font-size: 16px;
  min-height: 300px;
  color: gray(dark);
  letter-spacing: 0;
  box-sizing: border-box;

  img {
    margin-right: 0;
    margin-left: 0;
  }
}

.subNavBlock {
  border-bottom: 1px solid gray(mid);
  padding-bottom: 20px;
}

.formButton {
  margin-top: 50px;

  button, a {
    float: right;
  }
}

@media #{$tablet} {
  .formButton {
    margin-top: 0;
    margin-bottom: 20px;

    button, a {
      float: left;
    }
  }
}

.infoPanel {
  display: block;
  border: 1px solid icon-color(primary);
  border-radius: 2px;
  background-color: #FFFFFF;
  margin-bottom: 20px;
  h4 {
    margin-bottom: 0px;
  }
}
