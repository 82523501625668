@mixin moduleDebug($name) {
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    content: $name;
    padding: 2px;
    background-color: #ccc;
    font-weight: bold;
  }
}

@mixin templateDebug {
  div[class*="col-"] > div {
    border: 1px dashed #ccc;
  }
}
