@import '~sema-ui-components/dist/components/styles/typography';
@import '../../utils';

.debug {
  @include moduleDebug('BulletinModule');
}

.base {
  ul {
    padding: 0 0 0 25px;
    font-size: 20px;
  }
}
