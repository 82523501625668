.permissionListFilters {
  margin-left: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 50px;
}

.rightmostColumn {
  display: flex;
  align-items: center;
}

.spinner {
  margin-left: 10px;
}
