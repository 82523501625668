@import '~sema-ui-components/dist/components/styles/variables';

.tabContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  font-size: 16px;
  color: $gray-dark;
  letter-spacing: 0;
  box-sizing: border-box;
}

.hiddenChild {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.borderedTabContent {
  border: 1px solid $gray-mid;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.06);
}

.filters {
  text-align: right;
  margin-top: 15px;

  .filterHeader {
    display: inline;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    margin-right: 20px;
  }

  label {
    margin-bottom: 0;
  }

  .checkbox {
    margin-bottom: 0;
  }
}

.stateProduction {
  background-color: brand(success);
}

.stateTesting {
  background-color: brand(warning);
}

.usersTable {
  tr {
    td:nth-child(1), td:nth-child(2) {
      width: 15%;
    }
  }
}

.userTableInfo {
  padding: 0;
  width: 80%;
  float: right;
  tr {
    td:nth-child(1) {
      width: 45px;
    }
    td:nth-child(2) {
      padding: 0;
      width: 100%;
    }
  }
}

.navigationColumn {
  padding: 0;

  .navigation {
    float: left;
    margin-top: 15px;
  }

  .addButtons {
    float: right;
    margin-bottom: 15px;

    .optionHeader {
      font-size: 15px;
      font-weight: normal;
      line-height: 30px;
    }
  }
}

.userButtons {
  float: right;

  .modify {
    font-size: 15px;
    font-weight: normal;
  }

  .notLast {
    margin-right: 30px;
  }

  .delete {
    font-size: 15px;
    font-weight: normal;
  }

  .icon {
    vertical-align: middle;
    width: 12px;
    height: 16px;
    display: inline-block;
    svg {
      height: 16px;
    }
    margin-right: 10px;
  }
}

.ingressColumn {
  padding: 0;

  .topControl {
    float: left;
    margin-top: 30px;

    .useSwitch {
      display: inline-block;
      margin-right: 20px;
      vertical-align: middle;
      line-height: 12px;
    }

    .deleteButton {
      display: inline-block;
      font-size: 14px;
      padding-left: 15px;

      i {
        vertical-align: middle;
        margin-right: 10px;

        svg {
          height: 16px;
          vertical-align: text-top;
        }
      }
    }
  }
}

.newServiceModal {
  min-width: 600px;
  .newServiceTextfield {
    margin-bottom: 10px;
    input {
      width: 100%;
    }
  }
}

.hiddenAdminSwitch {
  position: absolute;
  top: 0;
  right: 0;
  min-height: 25px;
  min-width: 90px;
  z-index: 10000;

  & div {
    display: none;
  }

  &:hover {
    & div {
      display: inline-block;
    }
  }
}

.usersInfo {
  margin-left: 185px;
  padding: 0;

  .usersInfoIcon span{
    width: 24px;
    height: 24px;
    line-height: 24px;
    background: text-color(primary);
  }

  .usersInfoText {
    line-height: 20px;
    font-size: 15px;
  }
}