@import '../../utils';
@import '~sema-ui-components/dist/components/styles/variables';

.debug {
  @include moduleDebug('TextBoxes');
  border: 1px dashed #ccc;
}

@media #{$desktop-large} {
  .desktopStyles {
    margin-left: 10px;
  }
  .secondBox {
    margin-top: 0;
  }
}

@media #{$desktop} {
  .desktopStyles {
    margin-left: 0;
  }
  .secondBox {
    margin-top: 25px;
  }
}

@media #{$mobile} {
}

.boxBase {
  margin-top: 25px;
}