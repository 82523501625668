@import '~sema-ui-components/dist/components/styles/variables';

.topDiv {
  position: relative;
}

.topButtonDiv {
  position: absolute;
  top: 30px;
  right: 0px;
}

.topButton {
  margin-right: 10px;
}

.middleButton {
  margin-top: 30px;
  margin-bottom: 50px;
}

.tableDiv {
  margin-top: 60px;
  position: relative;

  table {
    table-layout: fixed;
    word-wrap: break-word;
  }
}

.addButtonDiv {
  position: absolute;
  top: -60px;
  right: 0px;
}

.checkbox {
  small: true;
}

.linkButton {
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
}

.deleteButtonBar {
  margin-top: 40px;
}

.warningDiv {
  margin-top: 30px;
}

.tableDiv > div > div > div > div:nth-child(1) > div
{
  width: 320px;
}

.filterCheckboxes{
  float: right;
  margin-top: 12px;
}
.icon {
  display: table-cell;
  padding-left: 10.5px;
  padding-right: 10.5px;
  height: 30px;
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  color: #FFF;
  text-align: center;
  vertical-align: middle;
}

.infoBox {
  display: inline-block;
  vertical-align: middle;

  .infoBoxHeader {
    text-transform: uppercase;
  }

  h6 {
    font-size: 13px;
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 20px;
  }

  p:last-child {
    margin-bottom: 0px;
  }
}

.infoPanel {
  display: block;
  border: 1px solid icon-color(primary);
  border-radius: 2px;
  background-color: #FFFFFF;
}
