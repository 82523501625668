@import '~sema-ui-components/dist/components/styles/variables';
@import '~sema-ui-components/dist/components/styles/tables';

.hero {
  position: relative;
  background-size: cover;
  background-position: 50% 0;
  padding-top: 110px;
  padding-bottom: 110px;
  background-repeat: no-repeat;
  width: 100%;
  color: gray(dark);

  &.dark {
    color: #fff;
  }

  .backLink {
    position: absolute;
    top: 20px;
    left: 20px;
  }

  h1 {
    text-align: center;
    font-size: 40px;
    letter-spacing: 0;
    line-height: 52px;
    text-shadow: 0 1px 1px rgba(0,0,0,0.50);
    margin-bottom: 90px;
  }

  time {
    display: block;
    text-align: center;
    font-size: 16px;
    line-height: 30px;
    text-shadow: 0 1px 1px rgba(0,0,0,0.50);
  }
}

.content {
  $extraNegativeMargins: 50.75px;

  background-color: #fff;
  padding: 50px;
  margin-top: -100px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 100;

  p {
    font-size: 20px;
    line-height: 30px;
    font-weight: 100;
  }

  &.wantIngress {
    & > p:first-child {
      font-size: 24px;
      color: #2C2C2C;
      line-height: 35px;
      font-weight: 400;
    }
  }

  img {
    width: calc(100% + 100px);
    height: calc(100% + 100px);
    margin-left: -$extraNegativeMargins;
  }

  p > img {
    & + em {
      color: text-color(secondary);
      font-size: 16px;
      line-height: 25px;
      display: block;
      width: calc(100% + 100px);
      height: calc(100% + 100px);
      margin-left: -$extraNegativeMargins;
      font-style: normal;
    }
  }

  figcaption {
    color: text-color(secondary);
    font-size: 16px;
    line-height: 25px;
    display: block;
    width: calc(100% + 100px);
    height: calc(100% + 100px);
    margin-left: -$extraNegativeMargins;
    font-style: normal;
  }

  & > blockquote {
    position: relative;
    font-style: italic;
    font-size: 30px;
    font-weight: 100;
    line-height: 40px;
    padding-left: 50px;
    margin-top: 40px;
    margin-bottom: 50px;

    &::before {
      color: brand(blue);
      font-size: 100px;
      font-weight: 600;
      content: '“';
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
      position: absolute;
      line-height: 0;
      left: 0;
      top: -10px;
    }
  }

  table {
    @include paha-table;

    @media #{$mobile} {
     font-size: 14px;
    }

  }

  .outerWrap {
    position: relative;
  }

  .tableWrap {
    margin-left: -$extraNegativeMargins;
    margin-right: -$extraNegativeMargins;
    overflow-x: auto;
    position: relative;
    margin-bottom: $extraNegativeMargins / 2;

    table {
      margin-left: auto;
      margin-right: auto;
    }
  }

  $buttonWidth: 50px;

  .tableButton {
    height: $buttonWidth;
    width: $buttonWidth;
    border: 1px solid gray(mid);
    border-radius: $buttonWidth / 2;
    background-color: white;
    position: absolute;
    top: 50%;
    bottom: 1px;
    z-index: 2;

    &.long {
      top: 200px;
    }

    &.tableButtonRight {
      right: -$extraNegativeMargins - $buttonWidth / 2;

      &::after {
        position: absolute;
        left: 17px;
        top: 19px;
        width: 10px;
        height: 10px;
        content: "";
        border: 0 solid interaction-color(primary);;
        border-right-width: 2px;
        border-bottom-width: 2px;
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
    }

    &.tableButtonLeft {
      left: -$extraNegativeMargins - $buttonWidth / 2;

      &::after {
        position: absolute;
        right: 17px;
        top: 19px;
        width: 10px;
        height: 10px;
        content: "";
        border: 0 solid interaction-color(primary);;
        border-right-width: 2px;
        border-bottom-width: 2px;
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-transform: rotate(-225deg);
        transform: rotate(-225deg);
      }
    }
  }

  .tableScrollFadeRight {
    position: relative;

    &::after {
      content: "";
      z-index: 1;
      position: absolute;
      top: 1px;
      bottom: 1px;
      right: -$extraNegativeMargins - 1;
      border-right: 1px solid gray(mid);
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,fcfcfc+100&0+0,1+100 */
      background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      // filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#fcfcfc', GradientType=1);
      /* IE6-9 */
      width: 50px;
    }
  }

    .tableScrollFadeLeft {
      position: relative;

      &::before {
        content: "";
        z-index: 1;
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: -$extraNegativeMargins - 1;
        border-left: 1px solid gray(mid);
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,fcfcfc+100&0+0,1+100 */
        background: -moz-linear-gradient(right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        // filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#fcfcfc', GradientType=1);
        /* IE6-9 */
        width: 50px;
      }
  }

  pre {
    margin-left: -50px;
    margin-right: -50px;
  }

  td > pre {
    margin-left: 0;
    margin-right: 0;
  }

  pre > code {
    border: 1px solid gray(mid);
    background: gray(lightest);
    display: block;
    width: 100%;
    color: #282828;
    font-family: Monaco, monospace;
    padding: 24px 20px 26px 20px;
    margin-bottom: 50px;
    margin-top: 20px;
    font-size: 13px;
    line-height: 140%;
    white-space: pre-wrap;
  }
}

