@import '~sema-ui-components/dist/components/styles/variables';

.messageBody {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 25px;
  margin-right: 20px;

  p {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 24px;
  }

  .dismissButton {
    display: inline;
    font-weight: 500;
  }

  .applicationsButton {
    margin-top: 10px;
    font-weight: 500;
  }

  .createDate {
    font-size: 14px;
  }

  .icon {
    vertical-align: middle;
    width: 45px;
    height: 24px;
    margin-right: 25px;
    margin-top: 20px;

    > span {
      display: inline-block;
      background: gray(dark);
      border-radius: 15px;
      line-height: 30px;
      width: 30px;
      text-align: center;
      font-size: 15px;
      font-weight: 600;
      vertical-align: middle;
      color: white;
      background: secondary-color(jarvi);
    }
  }
}