@import '~sema-ui-components/dist/components/styles/variables';

$serviceCardWidths: (280, 580);

.servicecard {
  background: #fff;
  border: 1px solid $gray-mid;
  display: flex !important;
  margin-right: 20px;
  margin-bottom: 20px;
  height: 260px;
  color: gray(dark);
  padding: 10px;
  position: relative;

  @each $w in $serviceCardWidths {
    &.w#{$w} {
      width: #{$w}px;
    }
  }

  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.06);
  -webkit-backdrop-filter: blur(3px);
  overflow: hidden;

  h3 {
    margin-top: 5px;
    font-family: "Source Sans Pro";
    margin-bottom: 10px;
  }

  p {
    line-height: 20px;
    font-size: 16px;
  }

  .warningTextBox {
    height: 35px;
  }

  .topBar {
    margin-top: 5px;
    margin-left: 10px;
    margin-bottom: 10px;
  }

  .lockedContent {
    display: flex;
    align-items: center;
    margin-top: 60px;
    margin-left: 24px;

    svg {
      margin-right: 4px;
      margin-top: 6px;
      width: 24px;
      height: 24px;
      polyline {
        stroke: goldenrod;
        fill: goldenrod;
      }
      path {
        stroke: goldenrod;
        fill: goldenrod;
      }
    }
  }

  .lockedTitle {
    display: flex;
    align-items: center;

    .lockedIcon {
      margin-right: 8px;
      svg {
        margin-right: 4px;
        width: 24px;
        height: 24px;
        polyline {
          stroke: #000000;
        }
        path {
          stroke: #000000;
        }
      }
    }
  }

  .contentArea {
    margin-right: 10px;
    margin-left: 10px;
    .identificationContent {
      display: flex;
      .numOfServices {
        font-size: 42px;
      }
    }
  }

  .buttonContainer {
    position: absolute;
    margin-bottom: 30px;
    bottom: 0;
    display: flex;
    flex-direction: row;
    margin-right: 10px;
    .buttonDiv {
      margin-right: 10px;
    }
  }

  .balls {
    margin-right: 15px;
    vertical-align: middle;
    height: 45px;
    position: absolute;
    top: 0px;
    right: 0px;

    display: flex;
    align-items: center;

    > span {
      margin-top: 15px;
      margin-right: 15px;
      display: inline-block;
      border-radius: 15px;
      line-height: 28px;
      width: 30px;
      text-align: center;
      font-size: 15px;
      vertical-align: middle;
      box-sizing: border-box;
    }

    .alert {
      font-weight: 600;
      background: status-color(danger);
      color: white;
      border-style: solid;
      border-width: 1px;
      border-color: status-color(danger);
    }

    .warning {
      font-weight: 600;
      background: status-color(notification);
      color: gray(dark);
      border-style: solid;
      border-width: 1px;
      border-color: status-color(notification);
    }

    .info {
      font-weight: 400;
      background: white;
      border-style: solid;
      border-width: 1px;
      border-color: icon-color(secondary);
      color: icon-color(secondary);
      font-family: Georgia, serif;
      font-style: italic;
    }
  }
}

.notifStats {
  display: inline;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #023B84;
  color: white;
}

.infoBox {

  svg {
    path:nth-child(1) {
      fill: rgb(165, 172, 176);
    }
    path:nth-child(2){
      fill: #2A6EBB;
    }
    path:nth-child(3){
      fill: #2A6EBB;
    }
  }
}

.infoLink {
  line-height: 30px;
  font-size: 16px;

  a:link, a:visited, a:hover, a:active {
    color: text-color(primary);
  }
}

.mainService {
  display: block;
  width: 320px;
  position: relative;
  height: 220px;
  border-right: 1px solid $gray-mid;

  .buttonContainer {
    position: absolute;
    margin-bottom: 0;
    bottom: 0;
    display: block;
    margin-right: 10px;
    .buttonDiv {
      margin-right: 10px;
      margin-bottom: 0.5rem;
    }
  }
}

.subService {
  display: block;
  width: 230px;
  position: relative;
  height: 220px;

  .buttonContainer {
    position: absolute;
    margin-bottom: 0;
    bottom: 0;
    display: block;
    margin-right: 10px;
    .buttonDiv {
      margin-right: 10px;
      margin-bottom: 0.5rem;
    }
  }
}
