@import '~sema-ui-components/dist/components/styles/variables';

.fieldRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  span {
    font-size: 16px;
    line-height: 20px;
  }
  .fieldName {
    font-weight: 600;
    flex-basis: 85px;
    flex-shrink: 0;
  }
}

.detailedView {
  .fieldRow {
    .fieldName {
      flex-basis: 165px;
    }
  }
}

.organizationInfo {
  .fieldRow:last-of-type {
    margin-bottom: 0;
  }
}

.bold {
  font-weight: 600;
}

.infoBox {
  background-color: #FFFFFF;
  border: 1px solid border-color(border);
  margin-top: 50px;
}

.field_sub_text {
  color: text-color(secondary);
  font-size: 14px;
  line-height: 18px;
}

.formGroup {
  margin-bottom: 24px;

  label {
    font-weight: 600;
    margin-bottom: 16px;
  }

  &.last {
    margin-bottom: 0
  }
}

.spinner {
  margin-left: 10px;
  height: 14px;
  width: 14px;
}