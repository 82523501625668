@import '~sema-ui-components/dist/components/styles/variables';

$borderColor: bg-border-color(border);
.tabHeader {
  line-height: 30px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
  h2::after {
    border-bottom: none;
  }
}

.extraAreaWrapper {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  .extraArea {
    float: right;
    margin-bottom: 20px;
    margin-top: auto;
    padding-bottom: 13px;
    .stateArea {
      float: left;
      margin-right: 20px;
      margin-top: 10px;
      margin-bottom: 20px;
      .button {
        margin-left: 5px;
      }
      .stateLabel {
        display: inline;
        line-height: 30px;
        vertical-align: top;
        font-size: 18px;
        letter-spacing: -0.2px;
        font-weight: 600;
      }
      .stateIcon {
      }
      .stateName {
        display: inline;
        line-height: 30px;
        vertical-align: top;
        font-size: 15px;
      }
    }
  }
}

.accordionHeader {
  border-top: 1px solid $borderColor;
  border-right: 1px solid $borderColor;
  border-left: 1px solid $borderColor;
  padding-top: 15px;
  padding-left: 30px;
  padding-bottom: 25px;
  padding-right: 30px;
  .row {
    padding: 7px;
  }
}

.infoLineTitle {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}
.infoLineValue {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}

.imageStyle {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
}

.hiddenChild {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.checkCircle {
  @include icon-check-circle(text-color(secondary));
  border-width: 2px;
  margin-right: 20px;
  &:after {
    top: 6px;
    left: 10px;
  }
}

.caretClosed {
  @include icon-caret(secondary-color(jarvi));
  height: 12px;
  margin-left: 15px;
}

.caretOpen {
  @include icon-caret(secondary-color(jarvi), 45deg);
  height: 16px;
  margin-left: 15px;
}

.checkCircleFull {
  @include icon-check-circle(
    status-color(success),
    status-color(success),
    #fff
  );
  margin-right: 20px;
}

.accordionHeaderBase {
  display: flex;
  flex-direction: column;
  & > section {
    display: flex;
    flex-direction: column;
    .titleLine {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      margin-top: 16px;
      margin-bottom: 4px;
    }
    .valueLine {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
    }
  }
}

.contentTab {
  margin-left: 95px;
  margin-bottom: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  .valueLine {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 15px;
    &.indented {
      border-left: solid #a5acb0;
      padding-left: 29px;
      border-width: thin;
      margin-bottom: 38px;
      .titleField {
        margin-right: 11px;
      }
    }
    .titleField {
      flex: 1;
      margin-right: 40px;
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
    }
    .valueField {
      flex: 1;
      overflow: hidden;
      overflow-wrap: break-word;
      word-wrap: break-word; /* legacy browsers */
      font-size: 18px;
      line-height: 20px;
      font-weight: 500;
    }
    ol {
      padding-left: 15px;
    }
  }
  .numberedList {
    padding-top: 0;
    margin-top: 0;
  }
  ol {
    padding-left: 0px;
    ol {
      padding-left: 20px;
    }
  }
}

.mainFormTitle {
  margin-bottom: 5px;
}

.stateArea {
  float: left;
  margin-right: 20px;
  margin-top: 10px;
}

.pageHeader {
  margin-bottom: 20px;
}

.state {
  display: flex;
  flex-direction: row;
  align-items: center;
  .smallIcon {
    margin-right: 10px;
  }
}

.loadApplication {
  margin-top: 16px;
}

.noMarginBottom {
  margin-bottom: 0;
}

.formOperationButtons {
  .loadFormButton {
    margin-right: 10px;
  }
}

.checkboxLine {
  padding-left: 20px;
}

.questionBooleanAnswer {
  margin-bottom: 20px;
}

.listAnswer {
  margin-top: 20px;
}
