@import '~sema-ui-components/dist/components/styles/typography';
@import '~sema-ui-components/dist/components/styles/variables';
@import '../../utils';

$extraNegativeMargins: 50.75px;

.debug {
  @include moduleDebug('HeaderModule');
}

.headerBlock {
  margin-bottom: 50px;
}

.ingress {
  p {
    @include ingress;
  }
}

.image {
  > img {
    width: 100%;
  }
}

.wideImage {
  margin-left: -$extraNegativeMargins;
  margin-right: -$extraNegativeMargins;
}

@media #{$tablet} {
  .wideImage {
    margin-left: -30px;
    margin-right: -30px;
  }
}

@media #{$mobile} {
  .wideImage {
    margin-left: -10px;
    margin-right: -10px;
  }
}

