@import '~sema-ui-components/dist/components/styles/variables';

.icon {
  display: table-cell;
  padding-left: 10.5px;
  padding-right: 10.5px;
  height: 30px;
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  color: #FFF;
  text-align: center;
  vertical-align: middle;
}

.handledIcon {
  background: secondary-color(havumetsa);
}

.openIcon {
  background: secondary-color(lakka);
}

.draftIcon{
  background: secondary-color(kanerva);
}