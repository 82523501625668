@import '~sema-ui-components/dist/components/styles/variables';

.base {
  h2 {
    font-size: 38px;
    letter-spacing: 0;
    line-height: 50px;
    text-align: center;
    margin-bottom: 40px;
    font-weight: 100;
  }
}

.iconBox {
  margin-left: -25px;
  margin-right: -25px;
}

.hero {
  background-size: cover;
  background: url('../../assets/support_home_hero.svg') no-repeat 0 40px;
  padding-top: 70px;
  padding-bottom: 110px;
  width: 100%;
}

.searchField {
  width: 450px;
  margin: 0 auto;
}

.chooseService {
  margin-top: 90px;
  text-align: center;
  font-size: 24px;
  color: #2C2C2C;
  line-height: 30px;
  font-weight: 400;
}

.articleDiv {
  margin-top: 50px;
}

.articleTagsList {
  margin-bottom: 10px;
}

.backLink {
  margin-bottom: 20px;
}

.searchResults {
  width: 450px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background: #fff;
  z-index: 100;
  overflow: scroll;
  max-height: 320px;
  border-bottom: 1px solid bg-border-color(darker-bg);
}

.searchResultRow {
  border-style: solid;
  border-color: bg-border-color(darker-bg);
  border-width: 0px 1px 1px 1px;
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 20px;
}

.searchResultsTitle {
  font-size: 16px;
}

.searchResultsTagsList {
  font-size: 12px;
  margin-top: 5px;
}

.dFlexCenter {
  display: -webkit-flex; /* Safari */
  -webkit-justify-content: center; /* Safari 6.1+ */
  display: flex;
  justify-content: center;
}
