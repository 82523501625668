@import '~suomifi-design-tokens/dist/tokens';

.container {
  display: flex;
  flex-direction: column;
  gap: $fi-spacing-m;
  margin-bottom: $fi-spacing-m;
}

.group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.expanderContentHeader {
  margin-bottom: $fi-spacing-m;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// TODO: Remove duplicate styles on refactoring sprint
.person {
  &__container {
    border-bottom: 1px solid bg-border-color(border);
    background: #F7FAFD;
    padding: 1.5rem 1rem;
    margin-bottom: 1.5rem;
    border: 1px solid #C8CDD0;
  }

  &__heading {
    margin-top: $fi-spacing-m;
    margin-bottom: $fi-spacing-l;
  }

  &__button {
    margin-top: $fi-spacing-m;
    margin-bottom: $fi-spacing-m;
  }
}

.removePersonContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.textinput {
  margin-bottom: $fi-spacing-m !important;
}