@import '~sema-ui-components/dist/components/styles/variables';

.page {
  background: bg-color(content);
  color: text-color(primary);
  border: 1px solid border-color(border);
  padding: 40px 64px;
}

.page h3 {
  margin-top: 50px;
}

.page h4 {
  margin-top: 40px;
}

.saveButton {
  margin-right: 20px;
}

.dataTable {
  border-collapse: separate;
  border-spacing: 0 20px;
  font-size: 18px;
  margin: 20px 0;

  &.userPermissionEditTable {
    margin-top: 30px;
    margin-bottom: 40px;
  }

  th {
    vertical-align: top;
    padding-right: 30px;
  }

}

form.vertical label {
  margin-top: 24px;
}

.userListFilters {
  display: flex;
  align-items: flex-end;
  flex-flow: column;
}

.backLink {
  margin-bottom: 30px;
}

.inviteButton {
  margin-bottom: 15px;
}

.userTabs {
  margin-top: 15px;
}

.navigationColumn {
  display: flex;
  justify-content: space-between;
}

.requiredLabel::after {
  color: status-color(danger);
  content: " *";
}

.spaced {
  padding-bottom: 10px;
}
