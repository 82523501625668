@import '~suomifi-design-tokens/dist/tokens';
@import '~sema-ui-components/dist/components/styles/colors';

.container {
  display: flex;
  flex-direction: column;
  gap: $fi-spacing-m;
  align-items: baseline;

  @media only screen and (min-width: 1280px) {
    flex-direction: row;
  }
}
