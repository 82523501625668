@import '~suomifi-design-tokens/dist/tokens';

.container {
  display: flex;
  flex-direction: column;
  gap: $fi-spacing-m;
  margin-bottom: $fi-spacing-m;
}

.group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.hr {
  width: 100%;
  border: 0;
  height: 1px;
  background: $fi-color-depth-light1;
  margin-top: $fi-spacing-s;
  margin-bottom: $fi-spacing-s;
}

.listAnswer {
  margin-top: 2rem;
}

.listSubHeader {
  font-weight: bold;
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: .5rem;

}