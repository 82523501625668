@import '~sema-ui-components/dist/components/styles/variables';

.page {
  background: bg-color(content);
  color: text-color(primary);
  border: 1px solid border-color(border);
  padding: 40px 64px 60px 64px;
}

.saveButton {
  margin-right: 10px;
}
.requiredLabel::after {
  color: status-color(danger);
  content: " *";
}

.bold {
  font-weight: 600;
}

.noBottomMargin {
  margin-bottom: 0;
}

.userProfileForm {
  display: flex;
}

.section {
  margin-top: 30px;
  margin-bottom: 30px;
}