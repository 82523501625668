@import '~sema-ui-components/dist/components/styles/variables';

.userLabel {
  display: inline-block;
  color: #FFFFFF;
  font-size: 11px;
  line-height: 16px;
  font-weight: 600;
  margin-left: 10px;
  border-radius: 2px;
  padding: 0 5px;

  &.admin {
    background-color: brand-color();
  }

  &.ext {
    background-color: secondary-color(jarvi);
  }
}
