@import '../../utils';
@import '~sema-ui-components/dist/components/styles/variables';

.debug {
  @include moduleDebug('TextWithImgCol');
}

.base {
  display: flex;
  justify-content: center;
  margin-bottom: 70px;

  .image {
    flex: 0 0 25.5%;
  }

  div:first-child.image {
    position: relative;
    img {
      position: absolute;
    }
  }

  div:last-child.image {
    img {
      margin-left: 30px;
    }
  }

  .content {
    flex: 0 0 74.4%;
    margin-top: 14px;
  }

  .imagelessContent {
    flex: 0 0 100%;
    margin-top: 14px;
  }

}

@media #{$mobile} {
  .base {
    .image {
      display: none;
    }

    .content {
      flex: 0 0 100%;
    }
  }
}


