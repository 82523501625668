@import '~sema-ui-components/dist/components/styles/variables';

.page {
  background: bg-color(content);
  color: text-color(primary);
  border: 1px solid border-color(border);
  padding: 40px 64px;
}

.saveButton {
  margin-right: 10px;
}

.requiredLabel::after {
  color: status-color(danger);
  content: " *";
}

.extraContent {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}

.postalCode {
  display: inline-block;
  margin-right: 10px;
}

.city {
  display: inline-block;
}

.streetAddress {
  margin-bottom: 20px;
}

.intermediary {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  
  align-items: flex-start;

  p {
    max-width: 66.66%;
    @media #{$desktop} {
      max-width: 100%;
    }
  }
}
