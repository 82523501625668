@import '~sema-ui-components/dist/components/styles/variables';

.listbase {
  margin-top: 60px;
  h2 {
    margin-bottom: 60px;
  }
  h3 {
    margin-bottom: 7px;
  }
}

time {
  font-size: 14px;
  line-height: 20px;
}

.showMoreButton {
  margin-bottom: 50px;
}
