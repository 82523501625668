@import '~sema-ui-components/dist/components/styles/variables';

.base {
  margin-top: 30px;
}

.tabInside {
  margin-top: 30px;
  margin-bottom: 30px;
}

.icon {
  text-align: right;
  i {
    display: inline-block;
    width: 42px;
  }
}

.content {
  background: #fff;
  border: 1px solid $gray-mid;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.06);
  font-size: 16px;
  min-height: 300px;
  color: gray(dark);
  letter-spacing: 0;
  box-sizing: border-box;
  padding: 40px 50px;
}

.resultItem {
  margin-bottom: 20px;

  h4 {
    margin-bottom: 0;
  }

  .resultContent {
    margin-top: 5px;
    color: gray(dark);
    line-height: 22px;
    max-height: 45px;
    font-size: 16px;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

  }

  .resultCrumb {
    color: gray(base);
    font-size: 12px;
    line-height: 10px;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.noResults {
  font-style: italic;
  color: gray(alt);
}

.smallBlock {
  h5 {
    font-size: 16px;
  }

  .resultContent {
    display: none;
  }
}

.subTab {
  padding: 40px 60px;
}

.tags {
  font-size: 12px;
  color: text-color(secondary);
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 6px;
}

.svgSearchIcon {
  fill: gray(lighter);
}

.linkStrip {
  margin-top: 0px;
/*  background: rgba(4, 33, 63, 0.80); */
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
/*  padding-top: 39px; */
/*  padding-bottom: 19px; */

  .prettyLink {
    text-align: center;
/*    margin-bottom: 20px; */

    a {
/*      color: #fff; */
        color: #282828;

      i {
        display: inline-block;
        width: 22px;
        height: 22px;
        vertical-align: middle;
        margin-left: 10px;
      }

      .caretCircle {
        position: relative;
        display: inline-block;
        width: 22px;
        height: 22px;
        vertical-align: top;
        border: 2px solid #EA7125;
        border-radius: 11px;
        margin-left: 10px;

        &:after {
          position: absolute;
          left: 4px;
          top: 5px;
          width: 7px;
          height: 7px;
          content: "";
          border: 0 solid #EA7125;
          border-right-width: 2px;
          border-bottom-width: 2px;
          transform-origin: center center;
          transform: rotate(-45deg);
        }
      }
    }
  }
}

.counter {
  h6 {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.noResultsBlock {
  h3 {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

