@import '~sema-ui-components/dist/components/styles/variables';

.middleButtonDiv {
  margin-top: 30px;
  margin-bottom: 300px;
  width: 100%;
}

.middleButton {
  margin-top: 30px;
  margin-bottom: 50px;
}

.tableDiv {
  display: block;
}

.buttonRow {
  margin-right: 0px;
  padding-right: 0px;
  margin-left: 0px;
}

.addButtonDiv {
  display: inherit;
  margin-right: 0px;
}

.info {
  margin-right: 20px;
  margin-left: 20px;
  display: inline-block;
  border-radius: 15px;
  line-height: 28px;
  width: 30px;
  text-align: center;
  font-size: 15px;
  vertical-align: middle;
  box-sizing: border-box;

  font-weight: 400;
  border-style: solid;
  border-width: 1px;
  border-color: icon-color(secondary);
  color: icon-color(secondary);
  font-family: Georgia, serif;
  font-style: italic;
}

.linkButton {
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
}

.infoBox {
  vertical-align: middle;
  margin-top: 5px;
  margin-right: 15px;
}

.infoLink {
  line-height: 30px;
  font-size: 16px;

  a:link, a:visited, a:hover, a:active {
    color: text-color(primary);
  }
}

.infoPanel {
  display: block;
  border: 1px solid icon-color(primary);
  border-radius: 2px;
  background-color: #FFFFFF;
}

.infoPanelButton {
  right: 30px;
  position: absolute;
}

@media #{bp('<=tablet')} {
  .infoPanelButton {
    position: relative;
    display: block;
    margin: 0 auto;
  }
}
