@import '~sema-ui-components/dist/components/styles/variables';
@import '~sema-ui-components/dist/components/styles/core.scss';
@import 'bootstrap-theme';

.flexWrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  @media #{$mobile} {
    position: relative;
    left: 0;
    right: 0;
    transition: all 50ms linear;
  }

  &.sideNavOpen {
    @media #{$mobile} {
      position: relative;
      left: -320px;
      right: 320px;
      height: 100vh;
      transition: all 50ms linear;
    }
  }
}

.mainContainer {
  padding-top: 0;
  flex-grow: 1;
  flew-shrink: 1;
  flew-basis: auto;
}

.notificationRow {
  height: 0;
  padding: 0;
}

:global {
  html {
    background-color: gray(lighter);
  }

  h3 {
    font-size: 24px;
  }

  .card-container {
    background: #fff;
    border: 1px solid $gray-mid;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    font-size: 1rem;
    color: $gray-dark;
    letter-spacing: 0;
    box-sizing: border-box;
    padding: 1.25rem;
  }
}
