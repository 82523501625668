@import '~sema-ui-components/dist/components/styles/variables';

.tabContent {
  width: 100%;
  background: #fff;
  font-size: 16px;
  color: $gray-dark;
  letter-spacing: 0;
  box-sizing: border-box;
  padding-top: 50px;
  padding-bottom: 50px;

  p {
    font-size: 20px;
    line-height: 30px;
  }
}

.imageStyle {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
}

.hiddenChild {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.borderedTabContent {
  border: 1px solid $gray-mid;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.06);
}


.controlButtons {
  .confirmationButtons {
    float: left;
  }
  .cancelButton {
    float: right;
  }
}

.progressBar {
  ul {
    li:first-child {
      span {
        padding-left: 20px;
      }
    }
  }
}

.infoPoint {
  display: inline-block;
  vertical-align: middle;
}

.formHeader {
  p {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  ul {
    li {
      font-size: 18px;
      line-height: 27px;
    }
  }
}

.infoPanel {
  padding: 20px;
  padding-right: 50px;
  border: 1px solid #CCCCCC;
  display: flex;
  flex-direction: row;
  max-width: 740px;
  p {
    font-size: 16px;
    line-height: 24px;
  }
  .iconColumn {
    flex: 0 0 45px;
    .icon {
      height: 36px;
      width: 30px;
      svg {
        margin-right: 15px;
        height: 36px;
        width: 30px;
      }
    }
  }
  .contentColumn {
    flex: auto;
  }

}

.formContent {
  p {
    font-size: 16px;
    line-height: 24px;
  }
}

.textField {
  display: inline-block;
  margin-right: 20px;
  margin-top: 25px;
}

.requiredStar {
  color: status-color(danger);
}

.serviceFormHeader {
  p {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 10px;
  }
}

.transactionService {
  .serviceTitle {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .deleteServiceButton {
    display: inline-block;
    font-weight: normal;
    line-height: 26px;
    text-align: center;
    position: absolute;
    font-size: 16px;
  }
}

.transactionServiceInput {
  .transactionServiceName {
    display: block;
  }
  .transactionServiceDescription {
    display: block;
  }
}

.addTransactionService {
  font-size: 18px;
}

.readMore {
  font-weight: normal;
  font-size: 18px;
}

.tabHeader {
  line-height: 30px;
  margin-bottom: 0;
}

.checkCircle {
  @include icon-check-circle(text-color(secondary));
  border-width: 2px;
  margin-right: 20px;
  &:after {
    top: 6px;
    left: 10px;
  }
}

.caretClosed {
  @include icon-caret(secondary-color(jarvi));
  height: 12px;
  margin-left: 15px;
}

.caretOpen {
  @include icon-caret(secondary-color(jarvi), 45deg);
  height: 16px;
  margin-left: 15px;
}

.checkCircleFull {
  @include icon-check-circle(status-color(success), status-color(success), #FFF);
  margin-right: 20px;
}

.stateArea {
  float: left;
  margin-right: 20px;
  margin-top: 10px;
}

.extraArea {
  float: right;
  margin-bottom: 20px;
  .stateArea {
    float: left;
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
    .stateLabel {
      display: inline;
      line-height: 30px;
      vertical-align: top;
      font-size: 18px;
      letter-spacing: -0.2px;
      font-weight: 600;
    }
    .stateIcon {

    }
    .stateName {
      display: inline;
      line-height: 30px;
      vertical-align: top;
      font-size: 15px;
    }
  }

  .saveButton {

  }
}

.mainFormTitle {
  margin-bottom: 5px;
}

.mainFormDescription{
  font-size: 18px;
  line-height: 27px;
}

.fixedSizeButton {
  min-width: 150px;
  text-transform:capitalize;
  line-height: 20px;
}

.alignedButton {
  margin-left: 20px;
}

.description {
  max-width: 780px;
}

.controllButtons {
  background: #FFF;
  border-left: 1px solid border-color(border);
  border-right: 1px solid border-color(border);
  border-bottom: 1px solid border-color(border);

}

.infoBox {
  display: inline-block;
  vertical-align: middle;
  margin-left: 7px;
}

.pageHeader {
  margin-bottom: 20px;
}

.infoLabelHeading {
  display: inline-block;
  width: calc(100% - 35px);
}

.infoLabelIcon {
  display: inline-block;
  width: 35px;
}

.maxHeight400 {
  max-height: 400px;
  overflow: scroll;
}

