@import '~suomifi-design-tokens/dist/tokens';

@mixin wordWrap {
  word-break: break-all; /* very legacy */
  word-break: break-word; /* semi legacy */
  overflow-wrap: anywhere; /* present */
}

.table {
  width: 100%;
  background: $fi-color-white-base;
  border-collapse: collapse;
  border: 1px solid $fi-color-depth-light1;
}

.body {
  border-top: 3px solid $fi-color-highlight-base;
  border-bottom: 3px solid $fi-color-highlight-base;
}

.headerCell {
  @include fi-text-body-semi-bold-small;
  padding: $fi-spacing-xs $fi-spacing-s;
}

.row {
  border: 1px solid $fi-color-depth-light1;

  &:nth-child(even) {
    background: $fi-color-depth-light3;
  }
}

.cell {
  @include fi-text-body-text;
  @include wordWrap;
  padding: $fi-spacing-s;
}

.cellNoData {
  @include fi-text-body-text;
  padding: $fi-spacing-xl;
  text-align: center;
  font-style: italic;
  color: $fi-color-black-light1;
}

@media (max-width: 768px) {
  .header,
  .body {
    border-collapse: separate;
    border-spacing: $fi-spacing-xs;
  }

  .headerCell,
  .cell {
    display: table-row;
  }
}
