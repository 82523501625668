@import '~sema-ui-components/dist/components/styles/typography';
@import '../../utils';

.debug {
  @include moduleDebug('FileList');
}

.base {
  margin-bottom: 40px;

  h3 {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  ul {
    padding: 0;
    list-style: none;

    font-size: 18px;
    line-height: 23px;

    li {
      margin-bottom: 20px;
    }
  }
}
