@import '~sema-ui-components/dist/components/styles/variables';

.dateField {
  .date {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 5px;
  }
  .time {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 0;
  }
}

.filterUI {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  .serviceDropdown {
    margin-right: 10px;
    label {
      font-size: 14px;
    }
  }
  .stateCheckboxes {
    max-width: 540px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    .stateColumn {
      display: flex;
      flex-direction: column;
    }
  }
}

@media(max-width: 767px) {
  .stateCheckboxes {
    margin-top: 12px;
    float: left;
  }
}

.myFormsTable {
  table {
    table-layout: fixed;
  }
  table thead tr {
    th {
      &:nth-child(2) {
        padding-left: 0;
      }
      overflow:hidden;
    }
  }

  table tbody tr {
    td {
      &:nth-child(1) {
        position: relative;
        width: 30px;
        padding-top: 20px;
        padding-bottom: 20px;
      }
      &:nth-child(2) {
        width: 150px;
        padding-left: 0;
      }
      &:nth-child(3) {
        width: 350px;
      }
      &:nth-child(4) {
        width: 150px;
      }
    }
    .emptyDate {
      line-height: 39px;
    }
  }

  .infoRequestMarker {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 4px;
    background-color: status-color(notification);
  }
}

.displayAsLink {
  cursor:pointer;
  color: interaction-color(primary);
  &:hover {
    text-decoration:underline;
    color: interaction-color(dark);
  }
}
