@import '~sema-ui-components/dist/components/styles/variables';

.base {
  background-color: bg-color(content);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 1000;

  -webkit-box-shadow: 0 -1px 0 0 bg-border-color(border);
  -moz-box-shadow: 0 -1px 0 0 bg-border-color(border);
  box-shadow: 0 -1px 0 0 bg-border-color(border);

  .userCount {
    font-style: italic;
  }

  h5 {
    margin-top: 20px;
  }

  .container {
    padding-left: 30px;
  }

  .controls {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;

    label {
      font-size: 14px;
    }

    .tooltipTrigger {
      margin-left: 10px;
      margin-right: 30px;
      height: 40px;
      padding-top: 5px;
    }

    .roleSelect {
      margin-top: 20px;
    }

    .sendButton {
      margin-top: 20px;
      margin-left: 300px;
    }
  }
}

.roleDescriptionContainer {
  display: flex;
  flex-direction: column;
  .roleDescriptionTitle {
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .roleTitle {
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .roleDescription {

  }
}