@import '~suomifi-design-tokens/dist/tokens';

.container {
  background: $fi-color-white-base;
  margin: 0;
  padding: 0;
  border: 1px solid $fi-color-depth-light1;
  border-top: 4px solid $fi-color-highlight-base;
  border-radius: 5px;
}
