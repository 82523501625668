@import '~sema-ui-components/dist/components/styles/variables';

// Some global styles from _typograpgy.scss broke the
// suomifi-ui-components modal, so this is here to prevent that from happening
h2::after {
  border-bottom: none !important;
  width: auto !important;
}

.page {
  background: bg-color(content);
  color: text-color(primary);
  border: 1px solid border-color(border);
  padding: 40px 50px;
}

.form {
  max-width: 680px;

  h4 {
    margin-top: 35px;
    margin-bottom: 6px;
  }

  p {
    margin-bottom: 0;
  }

  .subDescription {
    line-height: 24px;
    font-size: 16px;
  }

  label {
    font-weight: 600;
    line-height: 20px;
    font-size: 16px;
  }

  .radioButton {
    label {
      font-weight: 500;
      line-height: 20px;
      font-size: 16px;
      margin-bottom: 15px;
      margin-top: 15px;
    }
  }

  .linkButton {
    font-weight: 500;
  }

  .vertical label {
    margin-top: 24px;
  }
}

.saveButton {
  margin-right: 20px;
}

.dataTable {
  border-collapse: separate;
  border-spacing: 0 20px;
  font-size: 18px;
  margin: 20px 0;

  &.userPermissionEditTable {
    margin-top: 30px;
    margin-bottom: 40px;
  }

  th {
    vertical-align: top;
    padding-right: 30px;
  }
}

form.vertical label {
  margin-top: 24px;
}

.userListFilters {
  display: flex;
  align-items: flex-end;
  flex-flow: column;
}

.backLink {
  margin-bottom: 30px;
}

.inviteButton {
  margin-bottom: 15px;
}

.userTabs {
  margin-top: 15px;
}

.navigationColumn {
  display: flex;
  justify-content: space-between;
}

.requiredLabel::after {
  color: status-color(danger);
  content: ' *';
}

.spaced {
  padding-bottom: 10px;
}

p.permissionTitle {
  margin-bottom: 10px;
}

p.permissionDesc {
  margin-bottom: 20px;
}

.tooltipTitle {
  margin-bottom: 30px;
}

.tooltipTrigger {
  display: inline-block;
  margin-left: 20px;
}

.fields {
  p {
    margin-bottom: 0;
  }
}

.fieldRow {
  display: flex;
  flex-direction: row;
  align-content: center;
  margin-bottom: 20px;
  .name {
    flex-basis: 200px;
    font-weight: 600;
  }
}

.state_label {
  padding-left: 5px;
  padding-right: 5px;
}

.state_select {
  line-height: 20px;
}
