@import '../../utils';
@import '~sema-ui-components/dist/components/styles/variables';

.debug {
  @include moduleDebug('IconBoxes');
  border: 1px dashed #ccc;
}

@media #{$desktop-large} {
  .desktopStyles {
    margin-left: 10px;
  }
}

@media #{$desktop} {
  .desktopStyles {
    margin-left: 0;
  }
}

.esuomiLink {
  margin-top: 36px;
  svg {
    margin-left: 12px;
    width: 14px;
    height: 14px;
    polyline {
      stroke: #2A6EBB;
    }
    path {
      stroke: #2A6EBB;
    }
  }
}

.desktopStyles {
  margin-bottom: 20px; 
}