@import '~sema-ui-components/dist/components/styles/variables';

.base {
  display: inline-block;

  .arrow {
    margin-left: 3px;
    width:19px;
    position: relative;
    cursor: pointer;

    .line {
      width:19px;
      height:2px;
      position: absolute;
      top: 9px;
      background: text-color(secondary);
    }

    .point {
      border: solid text-color(secondary);
      border-width: 0 2px 2px 0;
      display: inline-block;
      position: absolute;
      top: 6px;
      padding: 3px;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }
  }

  .link {
    display: block;
    font-size: 12px;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
    color: text-color(secondary);
    line-height: 19px;
    padding-left: 30px;
  }

  &.light {
    .arrow {
      .line {
        background: #fff;
      }

      .point {
        border: solid #fff;
        border-width: 0 2px 2px 0;
      }
    }
    .link {
      color: #fff;
    }
  }

}


