@import '../../utils';
@import '~sema-ui-components/dist/components/styles/variables';

.base {
  padding-bottom: 80px;
}

.debug {
  @include templateDebug;
}

.onMobile {
  display: none;
}
.aboveMobile {
  display: block;
}

@media #{$tablet} {
  .onMobile {
    display: block;
  }
  .aboveMobile {
    display: none;
  }
}