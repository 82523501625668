@import '~suomifi-design-tokens/dist/tokens';

.formContainer {
  display: flex;
  flex-direction: column;
  gap: $fi-spacing-xl;
  margin-bottom: $fi-spacing-m;
}

.spacer {
  width: 100%;
}