@import '~sema-ui-components/dist/components/styles/variables';

$active-text-color: $gray-dark;
$active-bg-color: #fff;
$active-border-color: $gray-mid;

.links {
  margin-top: 15px;
  line-height: 30px;
  padding: 0;

  li {
    list-style: none;

    a {
      color: text-color(primary);
      padding-left: 15px;

      &:hover,
      &:focus {
        text-decoration: none;
      }

      &:hover {
        padding-left: 10px;
        border-left: 5px solid interaction-color(primary);
      }
    }
  }

  .itemWithLongText {
    a {
      display: inherit;
      padding-left: 0px !important;
    }
    p {
      font-size: 16px;
      padding-left: 15px;
      padding-right: 5px;
      &:hover,
      &:focus {
        text-decoration: none;
      }

      &:hover {
        padding-left: 10px;
      }
    }
  }
}

.base {
  display: inline-block;
  position: relative;
}

.closeIcon {
  position: absolute;
  right: 0;
  top: 0;

  @media #{bp('>mobile')} {
    display: none;
  }
}

.authLevel {
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .authLevelTitle {
    color: #84898c;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
  }
}

.lockedMenuItem {
  margin-left: 15px;

  display: flex;
  align-items: center;

  span {
    color: #888;
  }

  .lockIcon {
    margin-left: 12px;
    height: 24px;
    width: 24px;
    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.active {
  .userDdBoxArrow {
    border-top: 1px solid bg-border-color(border);
    border-right: 1px solid bg-border-color(border);
    position: absolute;
    top: -8px;
    right: 9px;
    width: 14px;
    height: 14px;
    -ms-transform: rotate(-45deg); /* IE 9 */
    -webkit-transform: rotate(-45deg); /* Chrome, Safari, Opera */
    transform: rotate(-45deg);
    background: bg-border-color(content-bg);

    @media #{$mobile} {
      display: none;
    }

    @media only screen and (max-width: 320px) {
      right: 40px;
    }
  }

  .content {
    margin-top: 15px;
    display: block;
    height: auto;
    opacity: 1;
    transform: scaleY(1);
    transition: all 80ms ease-out;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
    color: text-color(primary);
    min-width: 320px;

    & > div:nth-child(2) {
      padding: 15px;
    }

    @media #{$mobile} {
      right: -330px;
      transform: initial;
      transform-origin: initial;
      transition: initial;
      top: -18px;
      margin-top: 0;
      width: 320px;
      height: 100vh;
    }
  }
}

.content {
  position: absolute;
  right: 0;
  opacity: 0.01;
  height: 0;
  width: 180px;
  z-index: 1000;
  font-size: 1rem;
  text-align: left;
  list-style: none;
  background-color: bg-border-color(content-bg);
  border: 1px solid bg-border-color(border);
  transform: scaleY(0);
  transform-origin: top;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2px;

  hr {
    margin: 0;
  }

  @media only screen and (max-width: 320px) {
    right: -10px;
  }

  @media #{$mobile} {
    top: -18px;
    opacity: 0.01;
    right: -690px;
    width: 0;
    height: 0;
    margin-top: 0;
    transform: initial;
    transform-origin: initial;
  }
}
.orgSwitcher {
  margin-top: 8px;
}

.menu_hamburger {
  background: none;
  border: none;
}
